import { Alert, Tooltip, Typography } from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import TileInfo from 'components/VenueTile/TileInfo';
import { AreaUnit } from 'stores/measurement-units';
import { i18n } from 'translation';
import {
    StyledDaysContainer,
    StyledFooterContainer,
    StyledFooterDetails,
    StyledGridHeader,
    StyledVenueBudgetLabel,
    StyledVenueImageDetails,
} from './CompareModalStyledComponents';
import CompareOnrivaTotals from './CompareOnrivaTotals';
import DayRows from './DayRows';
import { calculateBudgetStatus, getTotalDisplay } from './compareUtils';

type CollapsibleTableProps = {
    commonByDay: Bizly.VenueProposal['byDay'];
    proposalColumns: Bizly.VenueProposal[];
    areaUnit: AreaUnit;
    isSticky?: boolean;
    inquiryVenues: Bizly.Venue[];
    user: Bizly.User;
    eventBudget?: string;
};

function CollapsibleTable({
    commonByDay,
    proposalColumns,
    areaUnit,
    inquiryVenues,
    user,
    eventBudget,
}: CollapsibleTableProps) {
    //commonByDay just gets the first proposal [0] number of days so we can create how many rows of days

    const venueObjectById = Object.fromEntries(inquiryVenues.map((venue: Bizly.Venue) => [venue?.id, venue]));
    const isOnriva = !!user.team?.sourceSite;

    const renderProposalImageHeaders = (proposalColumns: Bizly.VenueProposal[]) => {
        return Array.from({ length: 4 }, (_, key) => {
            return (
                <Box key={key}>
                    {proposalColumns[key] ? (
                        <StyledVenueImageDetails>
                            <TileInfo
                                venue={venueObjectById[proposalColumns[key].venue?.id]}
                                pillType="inquiryStatus"
                            />
                            <img src={proposalColumns[key]?.venue?.imageUrl} />
                            <Typography component="p">{proposalColumns[key].venue?.name}</Typography>
                        </StyledVenueImageDetails>
                    ) : (
                        <Box /> // Empty Box for padding
                    )}
                </Box>
            );
        });
    };

    return (
        <>
            <StyledGridHeader>
                <Box />
                <Box />
                {renderProposalImageHeaders(proposalColumns)}
            </StyledGridHeader>
            <StyledDaysContainer>
                {commonByDay?.map((day, idx) => (
                    <DayRows
                        areaUnit={areaUnit}
                        key={idx}
                        proposalColumns={proposalColumns}
                        day={day}
                        dayNumber={idx + 1}
                    />
                ))}
            </StyledDaysContainer>
            <StyledFooterContainer>
                {isOnriva && <CompareOnrivaTotals proposalColumns={proposalColumns} />}
                <StyledFooterDetails>
                    <Typography fontSize={14} fontWeight={500}>
                        Proposal Total
                    </Typography>
                    {proposalColumns.map((proposal, key) => {
                        const { isAboveBudget } = calculateBudgetStatus(proposal, user, eventBudget);
                        const { totalProposal } = getTotalDisplay(proposal, isOnriva);
                        return (
                            <Box key={key}>
                                <StyledVenueBudgetLabel aboveBudget={isAboveBudget}>
                                    {totalProposal}
                                    {isAboveBudget && (
                                        <Tooltip
                                            sx={{ zIndex: 1 }}
                                            placement="top"
                                            title={i18n.venue.inquiry.aboveBudgetTooltip}
                                            arrow
                                        >
                                            <InfoOutlined fontSize="small" />
                                        </Tooltip>
                                    )}
                                </StyledVenueBudgetLabel>
                            </Box>
                        );
                    })}
                </StyledFooterDetails>
                <Box>
                    <Alert severity="info">
                        Proposal total does not calculate taxes and fees which the venue will include in their final
                        invoice.
                    </Alert>
                </Box>
            </StyledFooterContainer>
        </>
    );
}

export default CollapsibleTable;
