import Check from '@mui/icons-material/Check';
import {
    Box,
    StepLabel as MuiStepLabel,
    Step,
    StepConnector,
    stepConnectorClasses,
    StepIconProps,
    Stepper,
    styled,
} from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Heading1 } from 'components/BizlyOS/Typography/Typography';
import BizlyLogo from 'images/bizlyOS/bizly-logo.svg?react';
import React from 'react';
import { EColors, getColor } from 'theme';
import ContactDetails from './ContactDetails';
import SearchVenue from './SearchVenue';
import Successful from './Successful';
import VenueLocation from './VenueLocation';

const ClaimSteps = [
    { label: 'Venue Location', Element: VenueLocation },
    { label: 'Search Venue', Element: SearchVenue },
    { label: 'Contact Details', Element: ContactDetails },
];

const StepLabel = styled(MuiStepLabel)(({ theme: { getColor, EColors } }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiStepLabel-label': {
        color: getColor(EColors.pureWhite),
        fontSize: '1rem',
        fontWeight: '700',
    },
}));

const Connector = styled(StepConnector)(({ theme: { getColor, EColors } }) => ({
    marginLeft: '19px',
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: getColor(EColors.bizlyOSBorder),
        height: '50px',
    },
}));

const LoginInfo = styled(Body1)({
    marginBottom: '70px',
    '& span': { fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' },
});

const StepIconRoot = styled('div')<{ ownerState: { completed?: boolean; active?: boolean } }>(
    ({ theme: { getColor, EColors, spacing }, ownerState }) => ({
        margin: spacing(0.5),
        boxSizing: 'border-box',
        backgroundColor: getColor(EColors.pureWhite),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%',
        ...(ownerState.completed && {
            backgroundColor: getColor(EColors.bizlyOSPrimary),
        }),
        ...(ownerState.active && {
            border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
        }),
        '& .StepIcon-completedIcon': {
            fontSize: 20,
            color: getColor(EColors.pureWhite),
        },
    })
);

const ClaimStepContainerFooter = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    padding: spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: '0px -2px 4px 0px ' + getColor(EColors.bizlyOSBorder, 0.5),
    borderTop: '1px solid ' + getColor(EColors.bizlyOSBorder, 0.5),
    gap: spacing(1.5),
}));

function StepIcon(props: StepIconProps) {
    const { active, completed } = props;

    return (
        <StepIconRoot ownerState={{ completed, active }}>
            {completed ? (
                <Check className="StepIcon-completedIcon" />
            ) : (
                <Body1 fontWeight={600} color={active ? EColors.bizlyOSPrimary : EColors.bizlyOSBorder}>
                    {props.icon}
                </Body1>
            )}
        </StepIconRoot>
    );
}
type ListingsClaimDataType = {
    venue?: {
        name: string;
        placeId: string;
    };
    venueLocation?: {
        name: string;
        placeId: string;
    };
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    jobTitle?: string;
    department?: string;
};

export type ClaimStepProps = {
    onNextStepClicked: (data: ListingsClaimDataType) => void;
    onBackClicked: () => void;
    isLastStep: boolean;
    listingsClaimData: ListingsClaimDataType;
};

type ClaimStepContainerProps = ClaimStepProps & {
    children: React.JSX.Element | React.JSX.Element[] | string;
    disabled: boolean;
    onSave: () => void;
};

export function ClaimStepContainer({ children, isLastStep, disabled, onSave, onBackClicked }: ClaimStepContainerProps) {
    return (
        <Box
            flex={1}
            boxSizing="border-box"
            height="100%"
            display={'flex'}
            flexDirection="column"
            bgcolor={getColor(EColors.pureWhite)}
        >
            <Box flex={1} overflow="auto" display="flex" justifyContent="center" alignItems={'center'}>
                {children}
            </Box>
            <ClaimStepContainerFooter>
                <Button variant="text" onClick={onBackClicked}>
                    Back
                </Button>
                <Button sx={{ height: '37px', width: '150px' }} onClick={onSave} disabled={disabled}>
                    {isLastStep ? 'Submit' : 'Continue'}
                </Button>
            </ClaimStepContainerFooter>
        </Box>
    );
}

export default function ListingsClaim() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [listingsClaimData, setListingsClaimData] = React.useState<ListingsClaimDataType>({});

    const onNextStepClicked = (data: ListingsClaimDataType) => {
        const newData = { ...listingsClaimData, ...data };
        setListingsClaimData(newData);
        if (activeStep < ClaimSteps.length) {
            setActiveStep(activeStep + 1);
        } else {
            // TODO: handel claim submit here..
        }
    };

    const onBackClicked = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box
                width="540px"
                boxSizing="border-box"
                height="100%"
                bgcolor={getColor(EColors.pureBlack)}
                padding={6.25}
            >
                <BizlyLogo />
                <Heading1 color={EColors.pureWhite} marginTop="40px" marginBottom="10px">
                    Claim your venue’s listing
                </Heading1>
                <Body1 color={EColors.pureWhite} marginBottom="20px">
                    <span style={{ fontWeight: 700 }}>We’re excited that you want to claim your Bizly listing.</span> We
                    just need to confirm some information to get started
                </Body1>
                <LoginInfo color={EColors.pureWhite}>
                    If you already have a merchant account, <span>Log In</span>.
                </LoginInfo>
                <Stepper activeStep={activeStep} connector={<Connector />} orientation="vertical">
                    {ClaimSteps.map(({ label }, index) => (
                        <Step key={label} completed={index < activeStep}>
                            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            {activeStep < ClaimSteps.length ? (
                React.createElement(ClaimSteps[activeStep].Element, {
                    onNextStepClicked,
                    onBackClicked,
                    isLastStep: activeStep + 1 === ClaimSteps.length,
                    listingsClaimData,
                })
            ) : (
                <Successful />
            )}
        </Box>
    );
}
