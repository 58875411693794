import { Collapse } from '@mui/material';
import { StyledGridCell, StyledGridCellTitle, StyledGridContentTableRow } from './CompareModalStyledComponents';

type RenderContentRowProps = {
    open: boolean;
    title: string;
    content?: JSX.Element[] | JSX.Element;
    isTitleBold?: boolean;
    proposalColumns: Bizly.VenueProposal[];
    renderContent?: (proposalColumns: Bizly.VenueProposal) => JSX.Element;
};

function RenderContentRow({ open, title, isTitleBold, proposalColumns, renderContent }: RenderContentRowProps) {
    return (
        <StyledGridContentTableRow open={open}>
            <StyledGridCell>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <StyledGridCellTitle fontWeight={isTitleBold ? 500 : 400}>{title}</StyledGridCellTitle>
                </Collapse>
            </StyledGridCell>

            {proposalColumns?.map((proposal, key) => (
                <StyledGridCell key={key}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {renderContent ? renderContent(proposal) : ''}
                    </Collapse>
                </StyledGridCell>
            ))}
        </StyledGridContentTableRow>
    );
}

export default RenderContentRow;
