import { Box } from '@mui/material';
import { EColors } from 'theme';
import {
    DrawerActionButtons,
    DrawerBodyContent,
    DrawerBodyFooter,
    SpaceDrawerChildrenProps,
} from './AddEventSpaceDrawer';

import { zodResolver } from '@hookform/resolvers/zod';
import ImageInputField from 'components/BizlyOS/InputFields/ImageInputField';
import InputField from 'components/BizlyOS/InputFields/InputField';
import InputFieldWithToggle from 'components/BizlyOS/InputFields/InputFieldWithToggle';
import RichTextEditorField from 'components/BizlyOS/InputFields/RichTextEditorField';
import SelectField from 'components/BizlyOS/InputFields/SelectField';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetEventSpaceTypesQuery } from 'hooks/queries/BizlyOS/useListingQuery';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as z from 'zod';

const IMPERIAL_UNIT = 'sqft';
const METRIC_UNIT = 'sqm';

const DEFAULT_EVENT_SPACE_DATA: Bizly.OsEventSpace = {
    id: 0,
    slug: '',
    name: '',
    size: '',
    maxCapacity: 0,
    metric: 1,
    description: '',
    images: [],
    capacities: {
        banquetRounds: 0,
        boardRoomConference: 0,
        classroom: 0,
        cocktailRounds: 0,
        crescentRounds: 0,
        eshaped: 0,
        hollowSquare: 0,
        perimeterSeating: 0,
        talkShow: 0,
        theater: 0,
        tshaped: 0,
        ushaped: 0,
    },
    location: '',
    type: {
        id: 1,
        name: 'Dining',
    },
    updatedAt: '',
};

const AddEventSpaceSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    roomCategoryId: z.number().min(1, 'Room Category is required'),
    size: z.object({
        value: z.string().min(1, 'Size is required'),
        unit: z.string(),
    }),
    description: z.string().min(8, 'Description is required'),
    maxStanding: z.string().min(1, 'Max Standing Capacity is required'),
    maxSeated: z.string().min(1, 'Max Seated Capacity is required'),
    images: z
        .array(
            z.object({
                id: z.string(),
                srcUrl: z.string(),
                name: z.string(),
            })
        )
        .min(1, 'Images are required'),
});

type AddEventSpaceSchemaFormData = z.infer<typeof AddEventSpaceSchema>;

function formatNumber(num: number) {
    const rounded = num.toFixed(2);
    if (rounded.endsWith('.00')) {
        return rounded.slice(0, -3); // Remove ".00"
    }
    return rounded;
}

function convertArea(value: number, fromUnit: string) {
    const conversionFactor = 0.092903;

    if (fromUnit === IMPERIAL_UNIT) {
        return value * conversionFactor;
    } else if (fromUnit === METRIC_UNIT) {
        return value / conversionFactor;
    } else {
        return value;
    }
}

export default function AddEventSpaceDetails({
    stepNumber,
    totalSteps,
    onFinish,
    seletedEventSpace,
    isLast,
    setIsChanged,
}: SpaceDrawerChildrenProps) {
    const { listingId } = useParams() as { listingId: string };
    const { data: eventSpaceTypes } = useGetEventSpaceTypesQuery();

    const defaultValues = seletedEventSpace
        ? {
              name: seletedEventSpace.name,
              roomCategoryId: seletedEventSpace.type?.id,
              size: {
                  value: seletedEventSpace.size,
                  unit: seletedEventSpace.metric === 0 ? IMPERIAL_UNIT : METRIC_UNIT,
              },
              description: seletedEventSpace.description,
              maxStanding: '0',
              maxSeated: '0',
              images: seletedEventSpace.images.map(image => ({ ...image, id: image.id.toString() })),
          }
        : {
              name: '',
              roomCategoryId: 0,
              size: { value: '', unit: IMPERIAL_UNIT },
              description: '',
              maxStanding: '0',
              maxSeated: '0',
              images: [],
          };

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid, isSubmitting, isDirty },
    } = useForm<AddEventSpaceSchemaFormData>({
        resolver: zodResolver(AddEventSpaceSchema),
        defaultValues,
        mode: 'onChange',
    });

    const onSave = (data: AddEventSpaceSchemaFormData) => {
        const newData: Bizly.OsEventSpace = seletedEventSpace
            ? { ...seletedEventSpace }
            : { ...DEFAULT_EVENT_SPACE_DATA };

        newData.name = data.name;
        newData.description = data.description;
        newData.size = data.size.value;
        newData.metric = data.size.unit !== IMPERIAL_UNIT ? 1 : 0;
        newData.images = data.images.map((image, index) => ({ ...image, description: image.name, order: index }));
        onFinish(newData);
    };

    useEffect(() => {
        setIsChanged(isDirty);
    }, [isDirty, setIsChanged]);

    return (
        <>
            <DrawerBodyContent>
                <Box display="flex" flexDirection="column" gap={2.5} height={'100%'} marginBottom={2.5}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} required error={errors.name} label="Name" />;
                        }}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => {
                            return (
                                <RichTextEditorField
                                    {...field}
                                    required
                                    error={errors.description}
                                    label="Description"
                                />
                            );
                        }}
                    />

                    <Box display="flex" gap={2.5}>
                        <Box flex={1}>
                            <Controller
                                name="roomCategoryId"
                                control={control}
                                render={props => {
                                    const { field } = props;
                                    return (
                                        <SelectField
                                            {...field}
                                            value={field.value ? field.value : ''}
                                            required
                                            error={errors.roomCategoryId}
                                            label="Room Category"
                                            options={
                                                eventSpaceTypes?.map(type => ({
                                                    label: type.name,
                                                    value: type.id,
                                                })) || []
                                            }
                                        />
                                    );
                                }}
                            />
                        </Box>
                        <Box flex={1}>
                            <Controller
                                name="size"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <InputFieldWithToggle
                                            type="number"
                                            {...field}
                                            value={field.value.value}
                                            toggleValue={field.value.unit}
                                            onChange={event =>
                                                field.onChange({ value: event.target.value, unit: field.value.unit })
                                            }
                                            onChangeToggle={toggleValue => {
                                                const convertedValue = convertArea(
                                                    Number(field.value.value),
                                                    field.value.unit
                                                );
                                                field.onChange({
                                                    value: formatNumber(convertedValue),
                                                    unit: toggleValue,
                                                });
                                            }}
                                            required
                                            error={errors.size?.value}
                                            label="Size"
                                            toggleOptions={[
                                                { label: 'Sq. Ft', value: IMPERIAL_UNIT },
                                                { label: 'Sq. Mt', value: METRIC_UNIT },
                                            ]}
                                        />
                                    );
                                }}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" gap={2.5}>
                        <Box flex={1}>
                            <Controller
                                name="maxStanding"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <InputField
                                            {...field}
                                            type="number"
                                            required
                                            error={errors.maxStanding}
                                            label="Max Standing Capacity"
                                        />
                                    );
                                }}
                            />
                        </Box>

                        <Box flex={1}>
                            <Controller
                                name="maxSeated"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <InputField
                                            {...field}
                                            type="number"
                                            required
                                            error={errors.maxSeated}
                                            label="Max Seated Capacity"
                                        />
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                    <Controller
                        name="images"
                        control={control}
                        render={({ field }) => {
                            return (
                                <ImageInputField
                                    required
                                    label="Room Photos"
                                    limit={6}
                                    value={field.value}
                                    onChange={field.onChange}
                                    error={!!errors.images}
                                    listingId={listingId}
                                />
                            );
                        }}
                    />
                </Box>
            </DrawerBodyContent>
            <DrawerBodyFooter>
                <Body2 fontWeight="500" color={EColors.bizlyOSPrimary}>
                    Step {stepNumber}/{totalSteps}
                </Body2>
                <DrawerActionButtons
                    onCancel={() => reset()}
                    onSubmit={handleSubmit(onSave)}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isLast={isLast}
                />
            </DrawerBodyFooter>
        </>
    );
}
