import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Heading5 } from '../Typography/Typography';

const BackIcon = styled(KeyboardBackspaceIcon)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1rem',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    },
}));

type ListingHeaderProps = {
    state: 'create' | 'edit' | 'view';
    propertyTitle?: string;
    lastModified?: string;
    isChanges?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
};

export default function ListingHeader({ state = 'create', propertyTitle }: ListingHeaderProps) {
    const navigate = useNavigate();

    return (
        <>
            <Box display="flex" alignItems="center" gap={2.5}>
                <BackIcon onClick={() => navigate('/os/listings')} />
                <Heading5 sx={{ fontWeight: 700 }}>
                    {state === 'create' ? 'Create New Listing' : propertyTitle}
                </Heading5>
            </Box>
        </>
    );
}
