import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Menu,
    styled,
    Typography,
} from '@mui/material';
import { GridFilterListIcon, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { statusOptions } from 'shared';
import { Button } from 'ui';
import { StyledGridToolbarQuickFilter } from './Ui-V2/SearchInput/SearchInput';

const StyledFiltersContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const StyledMenu = styled(Menu)`
    .MuiMenu-paper {
        padding: 0 0.5rem;
        max-width: 280px;
    }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-bottom: 0rem;

    .MuiFormControlLabel-label {
        font-size: 0.875rem;
    }
`;

const StyledButton = styled(Button)`
    &.MuiButton-root {
        font-size: 0.875rem;
        border-radius: 4px;
        padding: 0.3rem 0.5rem;
        border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    }
`;

type GuestListTableToolbarProps = {
    value: string;
    handleSearch: (val: string) => void;
    filter: string | null;
    setFilter: (filter: string | null) => void;
};

export const GuestListTableToolbar = (props: GuestListTableToolbarProps) => {
    const { setFilter, filter } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterChange = (filterSelected: string | null) => {
        setFilter(filterSelected === filter ? null : filterSelected);
    };

    return (
        <GridToolbarContainer>
            <StyledFiltersContainer>
                <StyledButton variant="outline" onClick={handleClick} startIcon={<GridFilterListIcon />}>
                    Filters
                </StyledButton>
                <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <Typography fontSize={13} color="grey.500">
                        Filter by status (Select one)
                    </Typography>
                    <FormControl>
                        <FormGroup>
                            <Grid container>
                                {statusOptions.map(option => (
                                    <Grid item xs={6} key={option.id}>
                                        <StyledFormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    checked={filter === option.id}
                                                    onChange={() => handleFilterChange(option.id)}
                                                />
                                            }
                                            label={option.label}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </StyledMenu>
                <StyledGridToolbarQuickFilter placeholder="Search..." />
            </StyledFiltersContainer>
        </GridToolbarContainer>
    );
};
