import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Divider, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { AreaUnit } from 'stores/measurement-units';
import CollapsibleTable from './CollapsibleTable';
import { StyledDialogContent } from './CompareModalStyledComponents';

type CompareModalProps = {
    open: boolean;
    closeModal: () => void;
    venueProposals?: { [id: number]: Bizly.VenueProposal };
    areaUnit: AreaUnit;
    inquiryVenues: Bizly.Venue[];
    user: Bizly.User;
    eventBudget?: string;
};

function CompareModal({
    open,
    closeModal,
    venueProposals = {},
    areaUnit,
    inquiryVenues,
    user,
    eventBudget,
}: CompareModalProps) {
    const [isSticky, setIsSticky] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    const GALLERY_THRESHOLD = 140;

    useEffect(() => {
        const handleScroll = () => {
            if (modalRef.current) {
                setIsSticky(modalRef.current.scrollTop > GALLERY_THRESHOLD);
            }
        };

        const modalElement = modalRef.current;
        if (modalElement) {
            modalElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [open]);

    const transformVenueProposalObject = Object.entries(venueProposals).map(([key, proposal]) => ({
        venueId: key,
        byDay: proposal.byDay,
    }));
    const flatProposalColumns = Object.entries(venueProposals);
    const proposalColumns = flatProposalColumns.map(proposal => proposal[1]);

    const commonByDay = transformVenueProposalObject[0]?.byDay;

    return (
        <Dialog disablePortal open={open} onClose={closeModal} fullWidth maxWidth="lg">
            <DialogTitle>
                Compare Proposals
                <IconButton onClick={closeModal} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <Close />
                </IconButton>
            </DialogTitle>

            <Divider />
            <StyledDialogContent ref={modalRef} isSticky={isSticky}>
                <CollapsibleTable
                    isSticky={isSticky}
                    commonByDay={commonByDay}
                    proposalColumns={proposalColumns}
                    areaUnit={areaUnit}
                    inquiryVenues={inquiryVenues}
                    user={user}
                    eventBudget={eventBudget}
                />
            </StyledDialogContent>
        </Dialog>
    );
}

export default CompareModal;
