import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { createInquiryDraft } from 'api/inquiry';
import { EventCreateProtocolModal } from 'components/EventCreateModal';
import EventCreateModal from 'components/EventCreateModal/NewEventCreateModal';
import { Button } from 'components/Ui-V2/Button/Button';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { REJECTED_STATUSES } from 'components/VenueCard';
import { VenueListingSkeleton } from 'components/VenueListingSkeleton/VenueListingSkeleton';
import useShowModal from 'hooks/useShowModal';
import ImageGallery from 'pages/ImageGallery';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useUser } from 'providers/user';
import { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { addVenueToInquiryDraft, getVenue, getVenueSpaces } from '../api';
import VenueListingInfoV2 from '../components/VenueListing/VenueListingInfoV2';
import VenueListingInfoV3 from '../components/VenueListing/VenueListingInfoV3';
import { useAsync } from '../hooks/useAsync';

const FullWidthInquiryLoadingSkeleton = styled(Skeleton)`
    width: 100%;
    height: 36px;
`;

const Gallery = styled(Box)<{ pageOffset: number }>`
    position: relative;
    justify-content: center;
    align-items: center;
    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
`;

const StyledCantAddVenueButton = styled(Button)`
    width: 146px;
    height: 36px;
    border: none;
`;

const RemoveButton = styled(Button)`
    &.MuiButton-text {
        color: red;
    }
`;

const Container = styled(Box)`
    width: 100%;
`;

const PropertyId = styled(Typography)`
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
`;

const MinHeightContainer = styled(Box)`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

type VenueListingProps = {
    addToInquiry?: (venue: Bizly.Venue) => void;
    removeFromInquiry?: (venue: Bizly.Venue) => void;
    selectedVenues?: { [venueId: number]: Bizly.Venue };
    pageOffset?: number;
    onCreateEvent?: (eventId: number, venue: Bizly.Venue) => Promise<void>;
    useSearchParamId?: boolean;
    isLoadingInquiry?: boolean;
    updatingVenues?: boolean;
    hasAcceptedProposal?: boolean;
    onError?: (error: BizlyAPIError.WithErrorCode) => void;
};

const VenueListing = (props: VenueListingProps) => {
    const {
        addToInquiry,
        removeFromInquiry,
        selectedVenues,
        pageOffset = 0,
        onCreateEvent,
        useSearchParamId,
        isLoadingInquiry,
        hasAcceptedProposal,
        onError,
    } = props;

    const aiVenueContentFlag = useFeatureFlagEnabled('aiVenueContent');

    const { venueId: paramId } = useParams<{ venueId: string }>();
    const { eventId } = useParams();
    const [searchParams] = useSearchParams();

    const hidePropertyIdText = !!useSearchParamId;

    const id = useMemo(() => {
        const idStr = useSearchParamId ? searchParams.get('venueId') : paramId;
        return parseInt(idStr ?? '');
    }, [paramId, searchParams, useSearchParamId]);

    const getTheVenue = useCallback(
        () =>
            getVenue(id).catch(error => {
                onError?.(error);
                throw error;
            }),
        [id, onError]
    );

    const { data } = useAsync(getTheVenue);

    const getTheVenueSpaces: () => Promise<{
        spaces: Bizly.Space[];
    }> = useCallback(() => getVenueSpaces(id), [id]);

    const { data: spaces } = useAsync(getTheVenueSpaces);

    const { user } = useUser();

    const { venues } = useCurrentInquiry();

    const getVenueStatus = useCallback(
        (venueId: number) => {
            if (!venues) return;

            const currentVenue = venues.find(v => v.id === venueId);

            return currentVenue?.status;
        },
        [venues]
    );

    const venueData = useMemo(() => (data || { venue: undefined }).venue, [data]);

    const spacesData = useMemo(() => (spaces || { spaces: [] }).spaces, [spaces]);

    const isVenueRejected = useMemo(() => {
        const venueStatus = getVenueStatus(id);

        return venueStatus ? REJECTED_STATUSES.has(venueStatus) : false;
    }, [id, getVenueStatus]);

    const canAddVenue = !(venueData?.externalUrl || venueData?.type?.id === 10) && !isVenueRejected;

    const addVenue = useCallback(() => {
        if (addToInquiry && venueData) addToInquiry(venueData);
    }, [addToInquiry, venueData]);

    const removeVenue = useCallback(() => {
        if (removeFromInquiry && venueData) removeFromInquiry(venueData);
    }, [removeFromInquiry, venueData]);

    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal();

    const {
        modalShown: protocolModalShown,
        showModal: showProtocolModal,
        hideModal: hideProtocolModal,
    } = useShowModal();

    const venueAction = () => {
        if (isLoadingInquiry) {
            return <FullWidthInquiryLoadingSkeleton variant="rectangular" animation="wave" />;
        }

        if (hasAcceptedProposal) {
            return (
                <Button fullWidth disabled>
                    {i18n.venue.addedToInquiry}
                </Button>
            );
        }

        const isUnbookableDueToProtocol = user.team?.authMeetingCreateRedirect;

        if (isUnbookableDueToProtocol && onCreateEvent) {
            return (
                <Button onClick={showProtocolModal} disabled={venueData?.tempClosed}>
                    {i18n.venues.createMeeting}
                </Button>
            );
        }

        if (!canAddVenue) {
            return (
                <StyledCantAddVenueButton onClick={showCreateModal} disabled>
                    {i18n.venue.notBookable}
                </StyledCantAddVenueButton>
            );
        }

        if (selectedVenues?.[id]) {
            return (
                <Box>
                    <Button fullWidth disabled>
                        {i18n.venue.addedToInquiry}
                    </Button>
                    <RemoveButton fullWidth onClick={removeVenue} variant="text">
                        {i18n.venue.remove}
                    </RemoveButton>
                </Box>
            );
        } else {
            if (addToInquiry) {
                return (
                    <Button fullWidth onClick={addVenue} disabled={venueData?.tempClosed}>
                        {i18n.venue.addToInquiry}
                    </Button>
                );
            } else {
                return (
                    <Button onClick={showCreateModal} disabled={venueData?.tempClosed}>
                        {i18n.venues.createMeeting}
                    </Button>
                );
            }
        }
    };

    const onEventCreated = async (eventBody: Bizly.Event) => {
        if (!venueData) return;
        try {
            const inquiry = await createInquiryDraft(eventBody.id);
            const inquiryId = inquiry.inquiry.id;
            await addVenueToInquiryDraft(eventBody.id, {
                inquiryId,
                venueId: venueData.id,
            });
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    return (
        <MinHeightContainer>
            {venueData ? (
                <>
                    <Container>
                        {!hidePropertyIdText && (
                            <Stack>
                                <PropertyId>{`${i18n.venue.propertyId} #${venueData.id}`}</PropertyId>
                                <Spacer medium />
                            </Stack>
                        )}

                        <Stack direction="row" justifyContent={'center'} style={{ boxSizing: 'border-box' }}>
                            <Gallery pageOffset={pageOffset}>
                                {venueData.images && <ImageGallery images={venueData.images} />}
                            </Gallery>
                        </Stack>

                        <Stack>
                            <Spacer large />
                            {aiVenueContentFlag ? (
                                <VenueListingInfoV3
                                    venue={venueData}
                                    venueAction={venueAction()}
                                    spaces={spacesData}
                                    hideTopNavControl
                                    showCreateEventModal={showCreateModal}
                                    eventId={eventId}
                                />
                            ) : (
                                <VenueListingInfoV2
                                    venue={venueData}
                                    venueAction={venueAction()}
                                    spaces={spacesData}
                                    hideTopNavControl
                                    showCreateEventModal={showCreateModal}
                                    eventId={eventId}
                                />
                            )}
                        </Stack>
                    </Container>
                    {createModalShown && <EventCreateModal onClose={hideCreateModal} onEventCreated={onEventCreated} />}
                    {protocolModalShown && <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />}
                </>
            ) : (
                <VenueListingSkeleton />
            )}
        </MinHeightContainer>
    );
};

export default VenueListing;
