import { Box, Input, InputProps, styled } from '@mui/material';
import React, { HTMLInputTypeAttribute } from 'react';
import { FieldError } from 'react-hook-form';
import { EColors } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

const StyledInputField = styled(Input)<InputProps & { errror: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    height: '50px',
    fontSize: '0.875rem',
    border: '1px solid',
    borderColor: error ? 'red' : getColor(EColors.bizlyOSBorder),
    borderRadius: '4px',
    padding: '14.5px 10px',
    color: getColor(EColors.pureBlack),
    '& .MuiInput-input': {
        padding: 0,
    },
    '& .MuiInput-input:focus': {
        outline: 'none',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
    },
    '& .MuiInput-input:focus::placeholder': {
        color: getColor(EColors.pureBlack),
        opacity: 0.5,
    },
    '& .MuiInput-input::placeholder': {
        color: error ? 'red' : getColor(EColors.pureBlack),
    },
}));

type InputFieldProps = {
    label: string;
    placeholder?: string;
    error: FieldError | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    value: string | number | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
    type?: HTMLInputTypeAttribute;
};

const InputField = React.forwardRef<HTMLDivElement, InputFieldProps>(
    ({ label, error, type = 'text', placeholder, required = false, ...field }, ref) => {
        return (
            <Box>
                <Box display="flex" gap={0.5} marginBottom={1}>
                    <Body2 fontWeight={500}>{label}</Body2>
                    {required && (
                        <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                            *
                        </Body2>
                    )}
                </Box>
                <StyledInputField
                    type={type}
                    placeholder={placeholder ? placeholder : label}
                    error={!!error}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    disabled={field.disabled}
                    disableUnderline
                    ref={ref}
                />
                {!!error && (
                    <Caption color="red" fontWeight={500} marginBottom={1} size="large">
                        {error.message}
                    </Caption>
                )}
            </Box>
        );
    }
);

export default InputField;
