import { Box, Stack, styled as muiStyled } from '@mui/material';
import { DataGridPro, GridColDef, GridColumnHeaders, GridRowParams } from '@mui/x-data-grid-pro';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { Tab, TabContainer } from 'components/Ui-V2/TabGroup/TabGroup';
import EmptyStateImage from 'images/empty-pages-assets/empty_events.svg';
import { useState } from 'react';
import { i18n } from 'translation';

const TableWrapper = muiStyled(Box)<{ flex?: boolean }>(({ theme, flex }) => ({
    position: 'relative',
    flex: flex ? 1 : 'unset',
    boxSizing: 'border-box',
    padding: '20px',
    borderColor: theme.getColor(theme.EColors.bizlyOSBorder),
    borderWidth: '1px',
    borderStyle: 'solid',
}));

const StyledDataGridPro = muiStyled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,

    '&.MuiDataGrid-root': {
        maxHeight: '100%',
        border: 'none',
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[100],
    },
    '& .MuiDataGrid-overlayWrapper': {
        height: 'auto',
        marginTop: '104px',
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderTopWidth: 0,
    },
    '& .MuiDataGrid-row': {
        '--rowBorderColor': 'transparent',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-overlayWrapperInner': {
        height: 'auto !important',
    },
}));

const columns: GridColDef[] = [
    {
        field: 'location',
        headerName: i18n.workspaces.header.location,
        flex: 2,
    },
    {
        field: 'reservationId',
        headerName: i18n.workspaces.header.reservationId,
        flex: 1,
    },
    {
        field: 'type',
        headerName: i18n.workspaces.header.workspaceType,
        flex: 1,
    },
    {
        field: 'noOfPeople',
        headerName: i18n.workspaces.header.peopleCount,
        flex: 1,
    },
    {
        field: 'reservationDate',
        headerName: i18n.workspaces.header.reservationDate,
        flex: 1,
    },
    {
        field: 'reservationTime',
        headerName: i18n.workspaces.header.reservationTime,
        flex: 1,
    },
    {
        field: '',
        headerName: i18n.workspaces.header.action,
    },
];

type EmptyStateText = {
    title: string;
    description: string;
};

const emptyStateTexts: Record<TabValue, EmptyStateText> = {
    all: {
        title: 'No Reservations Made Yet',
        description: `You have not made any reservations yet. To create a new reservation, click on the “Book a workspace” button at the top.`,
    },
    upcoming: {
        title: 'No Upcoming Reservations',
        description: `You have no upcoming reservations right now. To create a new reservation, click on the “Book a workspace” button at the top.`,
    },
    past: {
        title: 'No Past Reservations',
        description: `You have not made any reservations as yet. To create a new reservation, click on the “Book a workspace” button at the top.`,
    },
    cancelled: {
        title: 'No Cancelled Reservations',
        description: `There are no cancelled reservations. To create a new reservation, click on the “Book a workspace” button at the top.`,
    },
};

export type TabValue = 'all' | 'upcoming' | 'past' | 'cancelled';

export type TabConfigItem = {
    icon?: React.ReactNode;
    label: string;
};

const tabConfig: Partial<Record<TabValue, TabConfigItem>> = {
    upcoming: {
        label: i18n.workspaces.upcoming,
    },
    past: {
        label: i18n.workspaces.past,
    },
    cancelled: {
        label: i18n.workspaces.cancelled,
    },
    all: {
        label: i18n.workspaces.all,
    },
};

const WorkspacesTable = () => {
    const [tab, setTab] = useState<TabValue>('upcoming');
    const [workspaces] = useState([]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: TabValue) => {
        setTab(newValue);
    };

    const handleRowClick = (params: GridRowParams<Bizly.Event>) => {
        console.log(params.row.id);
    };

    const { title, description } = emptyStateTexts[tab];

    return (
        <TableWrapper>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabContainer value={tab} onChange={handleTabChange}>
                    {Object.entries(tabConfig).map(([value, { icon, label }]) => (
                        <Tab key={value} icon={icon} label={label} value={value} />
                    ))}
                </TabContainer>
            </Box>
            <Spacer xsmall />
            <Stack>
                <StyledDataGridPro
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnResize
                    rows={workspaces}
                    columns={columns}
                    loading={false}
                    onRowClick={handleRowClick}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    rowCount={workspaces.length}
                    slots={{
                        noRowsOverlay: () => (
                            <EmptyVisual
                                image={<img src={EmptyStateImage} alt="No items" />}
                                title={title}
                                description={description}
                            />
                        ),
                        columnHeaders: workspaces.length === 0 ? () => null : GridColumnHeaders,
                    }}
                />
            </Stack>
        </TableWrapper>
    );
};

export default WorkspacesTable;
