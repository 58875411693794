import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Badge, Box, Chip, styled } from '@mui/material';
import { TProposalForm } from 'components/ProposalForm/types';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { formatDateRange } from 'utils/date_util';
import { Body2 } from '../Typography/Typography';

const primaryColor = getColor(EColors.bizlyOSPrimary);

const ItemWrapper1 = styled(Box)(({ theme }) => ({
    marginRight: theme.spacing(2.5),
    alignSelf: 'center',
}));

const ItemWrapper2 = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    padding: '5px 10px',
    borderLeft: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const BackIcon = styled(KeyboardBackspaceIcon)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1rem',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    },
}));

const NewChip = styled(Chip)(({ theme: { getColor, EColors } }) => ({
    fontSize: '0.9rem',
    lineHeight: '0.9rem',
    fontWeight: '500',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    cursor: 'pointer',
}));

type ProposalHeader = {
    proposalInquryForm: Partial<TProposalForm>;
};

export default function ProposalInquryHeader({ proposalInquryForm }: ProposalHeader) {
    const navigate = useNavigate();

    const calcGuestsCount = () =>
        proposalInquryForm.eventSpaces?.reduce(function (accumulator, currentValue) {
            if (currentValue.requestedGuests) {
                return accumulator + currentValue.requestedGuests;
            }
            return accumulator;
        }, 0);

    return (
        <>
            <Box display="flex" alignItems="center">
                <ItemWrapper1>
                    <BackIcon onClick={() => navigate(-1)} />
                </ItemWrapper1>
                <ItemWrapper1>
                    <Body2 fontWeight={500}>Inquiry {proposalInquryForm.proposal?.id}</Body2>
                </ItemWrapper1>
                <ItemWrapper1>
                    <NewChip label="New" />
                </ItemWrapper1>
                <ItemWrapper2>
                    <CalendarTodayOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500}>Date:</Body2>
                    <Body2 fontWeight={500}>
                        {formatDateRange(
                            proposalInquryForm.event?.startsAt || '',
                            proposalInquryForm.event?.endsAt || ''
                        )}
                    </Body2>
                </ItemWrapper2>
                <ItemWrapper2>
                    <PeopleAltOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500}>Guests:</Body2>
                    <Body2>{calcGuestsCount()}</Body2>
                </ItemWrapper2>
                <ItemWrapper2 style={{ cursor: 'pointer' }}>
                    <PersonAddAltOutlinedIcon fontSize="small" sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500} color={EColors.bizlyOSPrimary}>
                        Assign
                    </Body2>
                </ItemWrapper2>
            </Box>
            <Box display="flex" alignItems="center" gap={2.5}>
                <Badge color="error" variant="dot">
                    <ChatBubbleOutlineIcon sx={{ color: primaryColor }} />
                </Badge>
                <ItemWrapper2>
                    <SupportAgentIcon sx={{ color: primaryColor }} />
                    <Body2 fontWeight={500}>Support</Body2>
                </ItemWrapper2>
            </Box>
        </>
    );
}
