import { createEvent, updateEvent } from 'api';
import { BLANK_CVENT_ID } from 'api/cvent';
import { BLANK_TEMPLATE_ID, isBlankTemplateId } from 'api/eventTemplates';
import Form from 'components/Form';
import { pick, sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentInquiry } from 'stores/current-inquiry';
import { useCvent } from 'stores/cvent';
import { LoadEventTemplates, useEventTemplates } from 'stores/eventTemplates';
import styled from 'styled-components';
import { i18n } from 'translation';
import { CopyFaded } from 'ui';
import { tzMoment } from 'utils/moment';
import DashboardCard from './DashboardCard';
import {
    CventOption,
    EventCreateFields,
    EventCreateSchema,
    EventCreateSchemaWithTemplate,
    EventForm as EventFormValues,
    toBizlyEvent,
    validateForm,
} from './formSchema';
import ResourcesList from 'components/Playbook/ResourcesList';
import Box from '@mui/material/Box';

const Container = styled(DashboardCard)`
    position: relative;
    padding-top: 15px;
`;

type EventFormProps = {
    defaultFormValues?: Partial<EventFormValues>;
    event?: Bizly.Event;
    onClose?: (success?: boolean) => void;
    onCreateEvent?: (eventId: number) => Promise<void>;
    errors?: string[];
    submitting: boolean;
    setSubmitting: (submitting: boolean) => void;
};

const EventForm = forwardRef(
    ({ event, errors, onCreateEvent, onClose, defaultFormValues, setSubmitting }: EventFormProps, ref) => {
        const navigate = useNavigate();
        const { enqueueSnackbar } = useSnackbar();

        const { templates, loading: loadingEventTemplates } = useEventTemplates();
        const templateOptions = React.useMemo(
            () =>
                sortBy(templates, ['id']).map(template => ({
                    ...template,
                    name: isBlankTemplateId(template.id) ? (
                        <CopyFaded>{i18n.homepage.createMeetingModal.noPlaybook}</CopyFaded>
                    ) : (
                        template.name
                    ),
                })),
            [templates]
        );

        const { inquiry: currentInquiry, loading: loadingCurrent, loaded: loadedCurrent } = useCurrentInquiry();
        const hasInquiry = !loadingCurrent && loadedCurrent && !!currentInquiry;

        const { cventEvents, loadingCventEvents } = useCvent();
        const cventOptions = useMemo(() => {
            const namedEvents: CventOption[] = cventEvents.map(cventEvent => ({
                ...cventEvent,
                id: cventEvent.cventId,
                name: cventEvent.meetingName,
            }));
            namedEvents.unshift({
                id: BLANK_CVENT_ID,
                name: <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>,
                meetingName: '',
                cventId: '',
                status: '',
            });
            return namedEvents;
        }, [cventEvents]);

        const { user } = useUser();

        const budgetRequired = useMemo(() => {
            const checkFields = user.team?.authMeetingCreateFields;
            return checkFields?.includes('budget');
        }, [user]);

        const initialTemplate = useMemo(() => {
            const templateId = event?.templateId || defaultFormValues?.templateId;
            let template = {} as Bizly.EventTemplate;
            if (templateId) {
                template = templates.find(template => template.id === templateId) || ({} as Bizly.EventTemplate);
            }
            return template;
        }, [templates, event, defaultFormValues]);

        const [templateDescription, setTemplateDescription] = useState(initialTemplate.shortDescription || '');

        const initialCvent = useMemo(() => {
            const cventId = event?.cventId || defaultFormValues?.cventEventId;
            let cvent = {} as BizlyAPI.CventEvent;
            if (cventId) {
                cvent = cventEvents.find(c => c.cventId === cventId) || ({} as BizlyAPI.CventEvent);
            }
            return cvent;
        }, [cventEvents, event, defaultFormValues]);

        const initialLocation = React.useMemo(() => {
            if (event?.location && event.googlePlaceId) {
                return {
                    location: event.location,
                    googlePlaceId: event.googlePlaceId,
                };
            }
            if (defaultFormValues?.city) {
                return defaultFormValues.city;
            }
            if (initialTemplate.location) {
                return {
                    location: initialTemplate.location.name,
                    googlePlaceId: initialTemplate.location.googlePlaceId,
                };
            }
            return null;
        }, [event, defaultFormValues, initialTemplate]);

        const nextDay = tzMoment().add(1, 'day');

        const [value, setValue] = useState<EventFormValues>({
            ...(initialLocation && { city: initialLocation }),
            templateId: initialTemplate.id || BLANK_TEMPLATE_ID,
            cventEventId: initialCvent.cventId || BLANK_CVENT_ID,
            name: event ? event.name : defaultFormValues?.name,
            type: event ? event.type : defaultFormValues?.type,
            costCenter: event ? event.costCenter : defaultFormValues?.costCenter,
            budget: event ? event.budget : defaultFormValues?.budget,
            startDate: (event?.startsAt ? tzMoment(event.startsAt) : nextDay).format('YYYY-MM-DD'),
            startTime: (event?.startsAt ? tzMoment(event.startsAt) : nextDay).format('h:mm:ss a'),
            endDate: (event?.endsAt ? tzMoment(event.endsAt) : nextDay).format('YYYY-MM-DD'),
            endTime: (event?.endsAt ? tzMoment(event.endsAt) : nextDay).format('h:mm:ss a'),
            description: event?.description,
            format: event?.format,
            cventId: event ? event.cventId : defaultFormValues?.cventEventId || BLANK_CVENT_ID,
            department: event?.department,
        });

        const updateForm = ({ value: newValue }: { value: EventFormValues }) => {
            if (value.templateId !== newValue.templateId) {
                const newTemplate =
                    templates.find(template => template.id === newValue.templateId) || ({} as Bizly.EventTemplate);
                if (newTemplate.location?.name && newTemplate.location.googlePlaceId) {
                    newValue.city = {
                        location: newTemplate.location.name,
                        googlePlaceId: newTemplate.location.googlePlaceId,
                    };
                } else {
                    newValue.city = undefined;
                }
                newValue.type = newTemplate.meetingType;
                newValue.costCenter = newTemplate.costCenter;
                newValue.budget = newTemplate.budget;

                setTemplateDescription(newTemplate.shortDescription || '');
            }

            if (value.cventEventId !== newValue.cventEventId) {
                const newCvent = cventEvents.find(c => c.cventId === newValue.cventId) || ({} as BizlyAPI.CventEvent);
                if (!newCvent) {
                    newValue.cventEventId = BLANK_CVENT_ID;
                    newValue.cventId = BLANK_CVENT_ID;
                }
                if (newCvent.meetingName) {
                    newValue.name = newCvent.meetingName;
                }
            }
            setValue(newValue);
        };

        const memoizedErrors = useMemo(() => JSON.stringify(errors), [errors]);

        React.useEffect(() => {
            if (memoizedErrors && memoizedErrors !== 'undefined') {
                const parsedErrors = JSON.parse(memoizedErrors);
                parsedErrors.forEach((error: string) => {
                    enqueueSnackbar(error, { variant: 'error' });
                });
            }
        }, [memoizedErrors, enqueueSnackbar]);

        const immutableFields = useMemo(
            () => pick(defaultFormValues, user.team?.authMeetingCreateFields ?? []),
            [defaultFormValues, user.team]
        );

        React.useEffect(() => {
            // we want to override playbook values with the default form values that came from the link the user followed
            if (immutableFields) {
                setValue(curValue => ({
                    ...curValue,
                    ...immutableFields,
                }));
            }
        }, [immutableFields]);

        const formFields = useMemo(() => {
            const disabledFields: string[] | undefined = event
                ? [
                      ...(user.team?.authMeetingCreateFields || []),
                      'templateId',
                      'cventEventId',
                      ...(hasInquiry ? ['startDate', 'startTime', 'endDate', 'endTime'] : []),
                  ]
                : user.team?.authMeetingCreateFields;
            return EventCreateFields(
                event,
                user.team?.meetingInternalReferenceFieldLabel,
                user.team?.meetingCostCenterFieldLabel,
                disabledFields,
                user.team?.features.requiredInternalFields,
                templateOptions,
                cventOptions,
                value.city,
                templateDescription,
                budgetRequired,
                loadingCventEvents,
                user.team?.costCenters,
                user.team?.departments
            );
        }, [
            event,
            user,
            templateOptions,
            cventOptions,
            value.city,
            templateDescription,
            budgetRequired,
            loadingCventEvents,
            hasInquiry,
        ]);

        const formSchema =
            loadingEventTemplates || templates?.length
                ? EventCreateSchemaWithTemplate(
                      user.team?.features.hiddenInternalFields,
                      user.team?.cventIntegrationEnabled,
                      hasInquiry
                  )
                : EventCreateSchema(
                      user.team?.features.hiddenInternalFields,
                      user.team?.cventIntegrationEnabled,
                      hasInquiry
                  );

        const onSubmit = async () => {
            const error = validateForm(value, budgetRequired, user.team?.features.requiredInternalFields);
            if (error) {
                return enqueueSnackbar(error, { variant: 'error' });
            }
            const eventObject = toBizlyEvent(value);
            setSubmitting(true);
            try {
                if (event?.id) {
                    await updateEvent({ id: event.id, schedule: [], ...eventObject });
                } else {
                    const { event } = await createEvent(eventObject);
                    onCreateEvent && (await onCreateEvent(event.id));
                    navigate(`/event/${event.id}`);
                }
                onClose && onClose(true);
            } catch (e) {
                // leaving this direct error for Apple, even though it breaks i18n
                enqueueSnackbar(String(e), { variant: 'error' });
                setSubmitting(false);
            } finally {
                setSubmitting(false);
            }
        };

        useImperativeHandle(ref, () => ({
            onSubmit,
        }));

        return (
            <Container itemSpacing="default" frameless>
                <LoadEventTemplates />
                <Form
                    key={`${event?.id ?? 0}-${value.templateId}`}
                    fields={formFields}
                    schema={formSchema}
                    value={value}
                    onChange={updateForm}
                />
                <Box sx={{ marginTop: "-65px" }}>
                    <ResourcesList
                        playbookId={value.templateId as string | number}
                        readonly={false}
                    />
                </Box>
            </Container>
        );
    }
);

export default EventForm;
