import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NewEventIcon from 'images/bizlyOS/icons/new-event.svg';
import NewMessageIcon from 'images/bizlyOS/icons/new-message.svg';
import NewReviewIcon from 'images/bizlyOS/icons/new-review.svg';
import PeopleIcon from 'images/bizlyOS/icons/people.svg';
import ProfileReviewIcon from 'images/bizlyOS/icons/profile-review.svg';
import TaskWarningIcon from 'images/bizlyOS/icons/task-warning.svg';
import UpcomingEventIcon from 'images/bizlyOS/icons/upcoming-event.svg';
import VenueIcon from 'images/bizlyOS/icons/venue.svg';
import EmptyTemplateImage from 'images/empty-pages-assets/empty_todo.svg';
import { Tab, Tabs } from '../Tabs';
import { Body1, Body2 } from '../Typography/Typography';

type TasksType = {
    title: string;
    description: string;
    actionLabel: string;
    createdAt: Date;
    action: () => void;
    icon: string;
};

const BorderedContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: 400,
}));

const SectionHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '50px',
    boxSizing: 'border-box',
}));

const SectionBody = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    gap: theme.spacing(2.5),
    overflowY: 'auto',
}));

const ActionButton = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    color: getColor(EColors.bizlyOSPrimary),
    marginTop: spacing(2),
    fontSize: '0.875rem',
}));

const CenteredBox = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
});

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius * 2,
}));

const OutLineContainer = styled(Container)(({ theme }) => ({
    alignItems: 'flex-start',
    gap: theme.spacing(1.25),
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
}));

const WarningContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    backgroundColor: getColor(EColors.warningBackground, 0.1),
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
}));

const EmptyTasks = ({ title }: { title: string }) => {
    return (
        <CenteredBox>
            <img src={EmptyTemplateImage} alt="Empty Image" />
            <Body1 fontWeight={600}>{title}</Body1>
        </CenteredBox>
    );
};

const TaskItem = (task: TasksType) => {
    return (
        <OutLineContainer onClick={task.action}>
            <img src={task.icon} alt={task.actionLabel} />
            <Box>
                <Body1 fontWeight={600} lineHeight={1} marginBottom={1}>
                    {task.title}
                </Body1>
                <Body2>{task.description}</Body2>
                <ActionButton>
                    <Body2 fontWeight={500} color="bizlyOSPrimary">
                        {task.actionLabel}
                    </Body2>
                    <ArrowForwardIcon fontSize="small" />
                </ActionButton>
            </Box>
        </OutLineContainer>
    );
};

function TaskDashboard() {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState<'new' | 'outstanding'>('new');
    const [newTasks, setNewTasks] = useState<TasksType[]>([
        {
            title: 'People',
            description:
                'Add multiple point-of-contacts at your venue to make it easy for Bizly and planners to reach out, and give your colleagues access to Bizly.',
            actionLabel: 'Invite Users',
            createdAt: new Date(),
            action: () => {
                /** opens up the Invite New User drawer  */
            },
            icon: PeopleIcon,
        },
        {
            title: 'Venue Details',
            description:
                'Enrich your listing to gain more visibility on Bizly. The more details you provide, the better our AI can match you with event planners.',
            actionLabel: 'Enrich Listing',
            createdAt: new Date(),
            action: () => navigate('/os/listing'),
            icon: VenueIcon,
        },
        {
            title: 'Review Your Profile',
            description: 'Review your profile and contact info to make sure everything is current.',
            actionLabel: 'My Profile',
            createdAt: new Date(),
            action: () => {
                /** opens up the User profile drawer  */
            },
            icon: ProfileReviewIcon,
        },
    ]);
    const [outstandingTasks, setOutstandingTasks] = useState<TasksType[]>([
        {
            title: 'New Event Lead',
            description: 'New RFP from Apple! Review and respond now.',
            actionLabel: 'Review',
            createdAt: new Date(),
            icon: NewEventIcon,
            action: () => {},
        },
        {
            title: 'New Review',
            description: 'You have a new review from {Client}.',
            actionLabel: 'Review',
            createdAt: new Date(),
            icon: NewReviewIcon,
            action: () => {},
        },
        {
            title: 'New Message',
            description: 'New message from [user] at [Company].',
            actionLabel: 'Reply',
            createdAt: new Date(),
            icon: NewMessageIcon,
            action: () => {},
        },
        {
            title: 'Upcoming Event',
            description: "Don't forget: [Event Name] is coming up on [Date].",
            actionLabel: 'Review',
            createdAt: new Date(),
            icon: UpcomingEventIcon,
            action: () => {},
        },
    ]);

    return (
        <BorderedContainer>
            <SectionHeader>
                <Body1 fontWeight={600}>Your Tasks</Body1>
                <Tabs value={tabValue}>
                    <Tab value={'new'} label={'New'} onClick={() => setTabValue('new')} />
                    <Tab value={'outstanding'} label={'Outstanding'} onClick={() => setTabValue('outstanding')} />
                </Tabs>
            </SectionHeader>

            {tabValue === 'new' &&
                (newTasks.length > 0 ? (
                    <SectionBody>
                        {newTasks.map(task => (
                            <TaskItem {...task} />
                        ))}
                    </SectionBody>
                ) : (
                    <EmptyTasks title="No active tasks" />
                ))}
            {tabValue === 'outstanding' &&
                (outstandingTasks.length > 0 ? (
                    <SectionBody>
                        <WarningContainer>
                            <img src={TaskWarningIcon} alt="No task" />
                            <Body2 fontWeight={500}>
                                These items need your attention.
                            </Body2>
                        </WarningContainer>
                        {outstandingTasks.map(task => (
                            <TaskItem {...task} />
                        ))}
                    </SectionBody>
                ) : (
                    <EmptyTasks title="No active tasks" />
                ))}
        </BorderedContainer>
    );
}

export default TaskDashboard;
