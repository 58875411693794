import { useState } from 'react';
import { fields, schema, schemaWithoutGuestrooms } from 'components/FinalSpendTracking/finalSpendFormSchema';
import Form from 'components/Form';
import { H2Headline, H3Headline } from 'components/ui/Headline';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Spacer } from 'ui';
import EmptyEstimatesImage from 'images/empty-pages-assets/empty-estimates.png';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import Stack from '@mui/material/Stack';
import isEmpty from 'lodash/isEmpty';
import UploadBox from 'components/Ui-V2/UploadBox/UploadBox';

export type TTotalSpendForm = {
    av?: number;
    catering?: number;
    guestrooms?: number;
    misc?: number;
    roomRental?: number;
    total?: number;
    existingDocs: {
        invoice1?: string;
        invoice2?: string;
    };
    stagedDocs?: {
        invoiceUrls?: File[];
    };
    currency?: string | null;
};

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

const UploadContainer = styled(Stack)`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    padding: 1em;
    border-radius: 4px;
`;

export default function TotalSpendForm({
    booking,
    formValues,
    onFormUpdate,
    teamCompliance,
    readonly,
    disabled,
    hideHeader,
    hasBookedVenue,
}: Readonly<{
    booking: Bizly.Booking;
    formValues: TTotalSpendForm;
    onFormUpdate: (finalSpendForm: TTotalSpendForm) => void;
    teamCompliance: Bizly.TeamCompliance;
    readonly?: boolean;
    disabled?: boolean;
    hideHeader?: boolean;
    hasBookedVenue?: boolean;
}>) {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { existingDocs, currency, stagedDocs = {} } = formValues;

    const handleStagingDoc = async (files: File[], target: string) => {
        const MAX_MB = 41943040; // 40mb
        const isFileTooLarge = files.filter(file => file.size > MAX_MB);
        if (isFileTooLarge.length > 0) {
            isFileTooLarge.forEach(file => {
                enqueueSnackbar(i18n.error.tooLargeFile(file.name), {
                    variant: 'error',
                });
            });
        } else {
            onFormUpdate({
                ...formValues,
                stagedDocs: { ...stagedDocs, [target]: files }
            });

            setUploadedFiles([]);
        }
    };

    const invoices = ['invoice1', 'invoice2'] as const;
    const uploadedDocs = invoices
        .map(invoice => existingDocs[invoice as keyof typeof existingDocs])
        .filter((doc): doc is string => !isEmpty(doc));

    return (
        <>
            {!hideHeader && <Headline>{i18n.meetingDashboard.headerSection.spendTracking.finalSpendTracker}</Headline>}
            {hasBookedVenue ? (
                <>
                    <Form
                        fields={fields(currency ?? 'USD')}
                        schema={teamCompliance?.guestroomsDisabled ? schemaWithoutGuestrooms : schema}
                        value={{ ...formValues, currencyCode: currency ?? 'USD' }}
                        onChange={({ value }: { value: TTotalSpendForm }) => onFormUpdate(value)}
                        isNested={false}
                        densePadding={false}
                        readonly={readonly}
                        disabled={disabled}
                    />

                    <Spacer large />

                    <UploadContainer spacing={2}>
                        <H3Headline>{i18n.meetingDashboard.headerSection.spendTracking.uploadFinalInvoice} (required)</H3Headline>
                        <UploadBox
                            onUpload={(files) => {
                                handleStagingDoc(files, 'invoiceUrls');
                                setUploadedFiles(files);
                            }}
                            files={uploadedFiles}
                            target={'invoiceUrls'}
                            link={uploadedDocs.length ? uploadedDocs : []}
                            isResourceFile={false}
                        />
                    </UploadContainer>
                </>
            ) : (
                <>
                    <H2Headline>{i18n.meetingDashboard.headerSection.spendTracking.finalSpend}</H2Headline>
                    <Spacer />
                    <EmptyVisual
                        image={<img src={EmptyEstimatesImage} alt="Empty Estimates" />}
                        title={i18n.venue.proposal.noVenueBookedYet}
                        description={i18n.venue.proposal.totalSpendHelper}
                    />
                </>
            )}
        </>
    );
}
