import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { EColors } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

const StyledSelect = styled(Select)(({ theme: { getColor, EColors, shape } }) => ({
    height: '50px',
    width: '100%',
    fontSize: '0.875rem',
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + getColor(EColors.bizlyOSBorder),
        borderRadius: shape.borderRadius,
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme: { getColor, EColors } }) => ({
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    '&.MuiFormLabel-root': {
        top: '2px',
    },
    '&.Mui-focused': {
        color: getColor(EColors.pureBlack),
    },
}));

type SelectFieldProps = {
    options: { value: number | string; label: string }[];
    label: string;
    placeholder?: string;
    emptyMessage?: string;
    error: FieldError | undefined;
    onChange: (value: string) => void;
    onBlur: () => void;
    value: string | undefined | number;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
};

const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(
    ({ options = [], label, error, onChange, emptyMessage = '', required = false, ...field }, ref) => {
        return (
            <Box>
                <Box display="flex" gap={0.5} marginBottom={1}>
                    <Body2 fontWeight={500}>{label}</Body2>
                    {required && (
                        <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                            *
                        </Body2>
                    )}
                </Box>
                <FormControl fullWidth>
                    {!field.value && (
                        <StyledInputLabel shrink={false} sx={{ top: '-3px', color: '#000' }}>
                            Select
                        </StyledInputLabel>
                    )}
                    <StyledSelect IconComponent={KeyboardArrowDownIcon} onChange={onChange} {...field} ref={ref}>
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}

                        {options.length === 0 && !!emptyMessage ? (
                            <MenuItem value={'empty_message'} disabled>
                                {emptyMessage}
                            </MenuItem>
                        ) : null}
                    </StyledSelect>
                </FormControl>
                {!!error && (
                    <Caption size="large" color="red" fontWeight={500} marginBottom={1}>
                        {error.message}
                    </Caption>
                )}
            </Box>
        );
    }
);

export default SelectField;
