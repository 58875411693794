import RightDrawer from 'components/Drawer/RightDrawer';
import { useState } from 'react';
import { i18n } from 'translation';
import VenueTypeSelect from './VenueTypeSelect';

type TSearchDrawerProps = {
    open: boolean;
    onClose: () => void;
};

const SearchDrawer = ({ open, onClose }: TSearchDrawerProps) => {
    const [view] = useState<'select_type' | 'search_form'>('select_type');
    const [, setVenueType] = useState('');

    return (
        <RightDrawer
            drawerOpen={open}
            onClose={onClose}
            title={i18n.workspaces.drawer.title}
            subtitle={i18n.workspaces.drawer.subTitle}
        >
            {view === 'select_type' && <VenueTypeSelect onSelect={setVenueType} />}
        </RightDrawer>
    );
};

export default SearchDrawer;
