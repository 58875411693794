import Grid from '@mui/material/Grid';
import { MAX_INQUIRY_VENUE_COUNT } from 'constants/inquiry';
import { useCallback, useMemo } from 'react';
import VenueTile from '../VenueTile/VenueTileV2';

type TPaginatedVenuesGrid = {
    data: Bizly.Venue[];
    selectedVenues?: { [venueId: number]: Bizly.Venue };

    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    onVenueHover?: (venueId: number | null) => void;
    onVisit?: (venueId: number) => void;

    pinHighlightedVenue?: number;
    hideActions: boolean;
    hasAcceptedProposal?: boolean;
};

export const PaginatedVenuesGrid = ({
    data,
    selectedVenues,
    onSelect,
    onDeselect,
    onVenueHover,
    onVisit,
    pinHighlightedVenue,
    hideActions,
    hasAcceptedProposal,
}: TPaginatedVenuesGrid) => {
    const mouseLeave = useCallback(() => onVenueHover && onVenueHover(null), [onVenueHover]);
    const _selectedVenues = useMemo(
        () => (hideActions || !selectedVenues ? [] : selectedVenues),
        [hideActions, selectedVenues]
    );

    const hasReachedMaxInquiryCount = selectedVenues && Object.keys(selectedVenues).length >= MAX_INQUIRY_VENUE_COUNT;
    const disableSelection = hasAcceptedProposal || hasReachedMaxInquiryCount;
    const disabledToolTipMessage = hasAcceptedProposal
        ? undefined
        : hasReachedMaxInquiryCount && `${MAX_INQUIRY_VENUE_COUNT} venues already selected`;

    return (
        <Grid container spacing={2.5}>
            {data.map(v => (
                <Grid item key={v.id}>
                    <VenueTile
                        venue={v}
                        width="16.25em"
                        padding="0.625em"
                        onMouseLeave={mouseLeave}
                        onMouseOver={onVenueHover}
                        highlighted={v.id === pinHighlightedVenue}
                        onClick={onVisit}
                        hideActions={hideActions}
                        disableRejected
                        selectionDisabled={disableSelection}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        selected={!!_selectedVenues[v.id]}
                        disabledToolTipMessage={disabledToolTipMessage || ''}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
