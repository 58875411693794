import { RequirementType, venueTypes } from 'constants/venueTypes';
import queryString from 'query-string';
import { kmToM, mToKm, miToKm, takeFirstIfArray, toMiles } from '../../util';
import { TFilterValue } from './VenueSearchFilters';

export type TPlaceQuery = Partial<{
    q: string; // semantic name of the place
    place_id: string; // google place id
    lat: number;
    lng: number;
}>;

type TTypes = BizlyAPI.Venue.Types;

// formats values from the URL params to the form data
export function queryParamsToFormFilters(params: string): TFilterValue {
    const { types, guests } = queryString.parse(params);
    const { dinovaOnly, preferredOnly } = queryString.parse(params, {
        parseBooleans: true,
    });
    const parsed = queryString.parse(params, { parseNumbers: true });
    const radius = parsed.radius as Nullable<Distance.Meter>;

    return {
        dinovaOnly: !!dinovaOnly,
        types: types ? toArray(types as TTypes[]) : undefined,
        brandIds: parsed.brandIds ? toArray(parsed.brandIds as number[]) : undefined,
        preferredOnly: !!preferredOnly,
        radius: radius && radius !== null && typeof radius === 'number' ? toMiles(radius) : undefined,
        radiusKm: radius && radius !== null && typeof radius === 'number' ? mToKm(radius) : undefined,
        guests: guests ? Number(guests) : undefined,
    };
}

// a "place" must have either the semantic name (q) and the place id or lat/lng
export function queryParamsToPlace(params: string) {
    const { q, place_id, lat, lng } = takeFirstIfArray(queryString.parse(params));

    if (!q) return undefined;

    const parsedLat = (lat && !isNaN(parseFloat(lat)) && parseFloat(lat)) || undefined;
    const parsedLng = (lng && !isNaN(parseFloat(lng)) && parseFloat(lng)) || undefined;

    const hasPlace = place_id || (parsedLat && parsedLng);
    if (!hasPlace) return undefined;

    return { q, place_id: place_id || undefined, lat: parsedLat, lng: parsedLng };
}

export function parseMapVisibility(params: string): boolean {
    const { showMap } = takeFirstIfArray(queryString.parse(params));

    return showMap?.toLocaleLowerCase() !== 'false';
}

// converts form data and place query into URL params
export function toQueryParams(
    filters: TFilterValue & TPlaceQuery & { showMap?: boolean }
): BizlyAPI.VenueFacets & TPlaceQuery {
    const { radius, radiusKm, ...applicableFilters } = filters;

    return {
        ...applicableFilters,
        radius: radiusKm ? kmToM(radiusKm === 150 ? (16000 as Distance.Kilometer) : radiusKm) : undefined,
    };
}

// converts an array or non-array value into an array
function toArray<T>(singleOrArray: T | Array<T>): Array<T> {
    return ([] as Array<T>).concat(singleOrArray);
}

export const parseEventSearchParams = ({ venueSearchParameters, requirements }: Bizly.Event) => {
    const { radius, brandIds, types, ...params } = venueSearchParameters ?? {};
    const requirementTypes: BizlyAPI.Venue.Types[] = [];
    if (requirements && requirements.length > 0) {
        const requiredVenueTypes = requirements.reduce(
            (types, venueType) => [
                ...types,
                ...((venueTypes[venueType as RequirementType] || []) as BizlyAPI.Venue.Types[]),
            ],
            [] as BizlyAPI.Venue.Types[]
        );
        requirementTypes.push(...requiredVenueTypes);
    }
    return {
        ...params,
        types: requirementTypes.length > 0 ? requirementTypes : types,
        ...(radius ? { radius: toMiles(radius) } : { radiusKm: miToKm(15 as Distance.Mile) }),
        ...(brandIds ? { brandIds } : { brandIds: undefined }),
        dinovaOnly: params.dinovaOnly ?? false,
        preferredOnly: params.preferredOnly ?? false,
    };
};

export function sortAndStringify(input: any): string {
    // If input is an array, map over its elements and sort/stringify each element
    if (Array.isArray(input)) {
        return `[${input.map(sortAndStringify).join(',')}]`;
    }
    // If input is an object, recursively sort its keys and stringify each value
    if (input !== null && typeof input === 'object') {
        const sortedKeys = Object.keys(input).sort();
        const sortedObject = sortedKeys.reduce(
            (acc, key) => {
                acc[key] = sortAndStringify(input[key]);
                return acc;
            },
            {} as Record<string, string>
        );
        return `{${sortedKeys.map(key => `"${key}":${sortedObject[key]}`).join(',')}}`;
    }
    // For primitive values, directly stringify
    return JSON.stringify(input);
}
