import { Box, styled } from '@mui/material';
import { Body1, Heading2 } from 'components/BizlyOS/Typography/Typography';
import { Button } from 'components/Ui-V2/Button/Button';
import VenueNotFoundPlaceholder from 'images/placeholders/venue_not_found.svg?react';
import { i18n } from 'translation';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    gap: theme.spacing(2),
    minHeight: '640px',
    alignself: 'stretch',
}));

export const VenueNotFound = ({ handleClose }: { handleClose?: () => void }) => {
    return (
        <StyledBox>
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <VenueNotFoundPlaceholder style={{ marginLeft: '3rem' }} />
                <Heading2
                    sx={{
                        fontSize: '2rem',
                        fontWeight: 600,
                        mt: 2,
                    }}
                >
                    {i18n.venue.notFound.title}
                </Heading2>

                <Body1
                    align="center"
                    sx={{
                        maxWidth: '480px',
                        px: 3,
                        m: 2,
                    }}
                >
                    {i18n.venue.notFound.message}
                </Body1>
            </Box>

            <Button
                variant="contained"
                sx={{
                    mb: 4,
                    '&.MuiButton-containedPrimary': {
                        marginLeft: '1.5rem',
                    },
                }}
                onClick={handleClose}
            >
                {i18n.venue.notFound.confirmation}
            </Button>
        </StyledBox>
    );
};
