/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Box, Button, DialogContent, Link, Popover, styled } from '@mui/material';
import { H2Headline } from 'components/ui/Headline';
import { i18n } from 'translation';
import { Spacer } from 'ui';
import { VerbiageAlert } from './styled';
import Form from 'components/Form';
import InfoIcon from '@mui/icons-material/Info';
import { selectPlaybook, useEventTemplates } from 'stores/eventTemplates';
import { useUser } from 'providers/user';
import { RichTextDisplay } from 'components/Form/RichTextEditor';

type TProps = {
    formFields: any;
    value: any;
    updateForm: (value: any) => void;
    handleCancel?: () => void;
    handleBack?: () => void;
    handleContinue?: () => void;
    onSubmit?: () => void;
    submitting?: boolean;
    stepTitle: string;
    schema: any;
};

const StepContainer = styled(Box)({
    width: '500px',
});

const FormContainer = styled(Box)({
    height: '400px',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
});

const MediumDialogContent = styled(DialogContent)`
    width: 20rem;
    padding: 2rem;
`;

const AppleEventCreate = ({
    formFields,
    value,
    updateForm,
    handleCancel,
    handleBack,
    handleContinue,
    onSubmit,
    submitting,
    stepTitle,
    schema,
}: TProps) => {
    const { user } = useUser();
    const playbook = useEventTemplates(selectPlaybook(value.templateId));
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    
    return (
        <StepContainer>
            <H2Headline>{stepTitle}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link
                        onClick={e => setAnchorEl(e.currentTarget)}
                        style={{ cursor: 'pointer' }}
                    >
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}
            <FormContainer>
                <Form
                    key={playbook?.id ?? 0}
                    fields={formFields}
                    schema={schema}
                    value={value}
                    onChange={updateForm}
                />
            </FormContainer>
            <Spacer />
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
                {handleBack && (
                    <Button variant="text" onClick={handleBack}>
                        {i18n.button.back}
                    </Button>
                )}
                {handleCancel && (
                    <Button variant="text" onClick={handleCancel}>
                        {i18n.button.cancel}
                    </Button>
                )}
                {handleContinue && (
                    <Button variant="contained" onClick={handleContinue}>
                        {i18n.button.continue}
                    </Button>
                )}
                {onSubmit && (
                    <Button variant="contained" onClick={onSubmit} disabled={submitting}>
                        {i18n.button.create}
                    </Button>
                )}
            </Box>
        </StepContainer>
    );
};

export default AppleEventCreate;