import { TabPanelProps } from '@mui/lab/TabPanel';
import { alpha, Box, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTab = styled(Tab)<TabProps>(({ theme: { getColor, EColors } }) => ({
    width: 14,
    '&.MuiTab-root': {
        minWidth: 'unset',
        width: 'auto',
        minHeight: 'unset',
        height: '30px',
        padding: '0 10px',
        borderRadius: 4,
        marginRight: 4,
        textTransform: 'unset',
    },
    '&.MuiTab-textColorInherit': {
        color: getColor(EColors.pureBlack),
    },
    '&.Mui-selected': {
        backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.3),
        color: getColor(EColors.pureBlack),
    },
    '&.Mui-selected:hover': {
        backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.3),
    },
    '&:hover': {
        backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.1),
    },
}));

const StyledTabs = styled(Tabs)<TabsProps>(() => ({
    '&.MuiTabs-root': {
        minHeight: 'unset',
    },
    '& .MuiTabs-indicator': {
        opacity: 0,
    },
}));

const TabPanelContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2.5),
}));

const SecodaryTab = styled(Tab)<TabProps>(({ theme: { getColor, EColors } }) => ({
    '&.MuiTab-root': {
        minWidth: 'unset',
        textTransform: 'unset',
        height: '30px',
        padding: '20px 0px',
        marginRight: '30px',
        color: getColor(EColors.pureBlack),
    },
}));

const SecodaryTabs = styled(Tabs)<TabsProps>(({ theme: { getColor, EColors, spacing } }) => ({
    backgroundColor: getColor(EColors.pureWhite),
    padding: '0 ' + spacing(2.5),
    '&.MuiTabs-root': {
        minHeight: 'unset',
        borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    },
    '& .MuiTabs-indicator': {
        backgroundColor: getColor(EColors.bizlyOSPrimary),
        height: '3px',
    },
}));

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, id } = props;

    return (
        value === id && (
            <TabPanelContainer role="tabpanel" id={`simple-tabpanel-${id}`}>
                {children}
            </TabPanelContainer>
        )
    );
}

export { CustomTabPanel, SecodaryTab, SecodaryTabs, StyledTab as Tab, StyledTabs as Tabs };
