import {
    DndContext,
    DragEndEvent,
    DragOverlay,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Checkbox, styled } from '@mui/material';
import { Dispatch, HTMLAttributes, SetStateAction, forwardRef, useState } from 'react';
import { EColors, getColor } from 'theme';

const Grid = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    gridGap: spacing(1.25),
}));

const ImageContainer = styled(Box)(({ srcUrl }) => ({
    transformOrigin: '0 0',
    width: '100%',
    aspectRatio: 1,
    backgroundImage: `url("${srcUrl}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    position: 'relative',
    cursor: 'grab',
}));

const ImageMask = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: getColor(EColors.pureBlack, 0.6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: spacing(1.25),
}));

const StyledCheckbox = styled(Checkbox)({
    padding: 0,
    '& .MuiSvgIcon-root': {
        color: getColor(EColors.pureWhite),
    },
});

export interface ImageType {
    id: string;
    srcUrl: string;
    isSelected: boolean;
}

type SortableImageItemProps = {
    onItemSelect?: (id: string) => void;
} & ImageType;

type ImageProps = {
    isMouseOver?: boolean;
    dragStyle?: object;
} & SortableImageItemProps &
    HTMLAttributes<HTMLDivElement>;

const Image = forwardRef<HTMLDivElement, ImageProps>(
    ({ id, dragStyle, isMouseOver, onItemSelect, isSelected, ...props }, ref) => {
        return (
            <ImageContainer ref={ref} sx={dragStyle} {...props}>
                {(isMouseOver || isSelected) && (
                    <ImageMask>
                        <DragIndicatorIcon sx={{ color: getColor(EColors.pureWhite) }} />
                        <StyledCheckbox
                            checked={isSelected}
                            color="primary"
                            onChange={() => onItemSelect && onItemSelect(id)}
                        />
                    </ImageMask>
                )}
            </ImageContainer>
        );
    }
);

const SortableImageItem = (props: SortableImageItemProps) => {
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const sortable = useSortable({ id: props.id });
    const { attributes, listeners, setNodeRef, transform, transition } = sortable;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <Image
            isMouseOver={isMouseOver}
            onMouseOver={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            ref={setNodeRef}
            dragStyle={style}
            {...props}
            {...attributes}
            {...listeners}
        />
    );
};

type ImageSortableProps = {
    images: ImageType[];
    setImages: Dispatch<SetStateAction<ImageType[]>>;
    onChanged: () => void;
};

export default function ImageSortable({ images, setImages, onChanged }: ImageSortableProps) {
    const [active, setActive] = useState<ImageType | null>(null);
    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 250, tolerance: 200 } }));

    const handleSelectImage = (id: string) => {
        if (!id) return;
        setImages(images => {
            return images.map(image => {
                if (image.id !== id) return image;
                return {
                    ...image,
                    isSelected: !image.isSelected,
                };
            });
        });
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={images} strategy={rectSortingStrategy}>
                <Grid>
                    {images.map(image => (
                        <SortableImageItem key={image.id} {...image} onItemSelect={handleSelectImage} />
                    ))}
                </Grid>
            </SortableContext>
            <DragOverlay adjustScale={true}>{active ? <Image {...active} isSelected={false} /> : null}</DragOverlay>
        </DndContext>
    );

    function handleDragStart(event: DragEndEvent) {
        const image = images.find(image => image.id === event.active.id);
        setActive(image || null);
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (!active || !over) return;

        if (active.id !== over.id) {
            setImages(images => {
                let oldIndex = 0;
                let newIndex = 0;

                images.forEach((image, index) => {
                    if (image.id === active.id) oldIndex = index;
                    if (image.id === over.id) newIndex = index;
                });

                return arrayMove(images, oldIndex, newIndex);
            });
            onChanged();
        }

        setActive(null);
    }

    function handleDragCancel() {
        setActive(null);
    }
}
