import Close from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, styled, Typography } from '@mui/material';
import { getMeeting } from 'api/meetings';
import { QueryParamEnum } from 'constants/queryParams';
import useVenueInquiry from 'hooks/useVenueInquiry';
import VenueListing from 'pages/VenueListingV2';
import { EventFormFields, EventFormValues } from 'pages/Venues/Venues';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { MAX_INQUIRY_VENUE_SLOTS } from 'utils/venue';
import ConfirmationModal from './ConfirmationModal';
import { REJECTED_STATUSES } from './VenueCard';
import { VenueNotFound } from './VenueNotFound/VenueNotFound';

const StyledDialogContent = styled(DialogContent)`
    min-height: 20rem;
`;
type VenueDetailModalContentProps = {
    handleClose?: () => void;
    setShowRightDrawer?: (show: boolean) => void;
};

const VenueDetailModalContent = ({ handleClose, setShowRightDrawer }: VenueDetailModalContentProps) => {
    const [error, setError] = useState<BizlyAPIError.WithErrorCode | null>(null);
    const [event, setEvent] = useState<BizlyAPI.Meeting | Bizly.Event | null>(null);
    const location = useLocation();
    const { eventId } = useParams();

    const useQuery = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const query = useQuery;
    const venueId = query.get(QueryParamEnum.VENUE_ID);

    const handleLoadingStatus = (loading: boolean) => {
        if (loading) {
            setError(null);
        }
    };

    const submitInquiryHook = (venues?: Bizly.Venue[] | []) => {
        const selectedVenues = venues;
        const validVenues = selectedVenues?.filter(venue => !REJECTED_STATUSES.has(venue.status));
        const isFirstVenueAdded = validVenues && validVenues.length === 0;
        const isLastAvailableSlotAdded = validVenues && validVenues.length === MAX_INQUIRY_VENUE_SLOTS - 1;
        if (isFirstVenueAdded || isLastAvailableSlotAdded) {
            setShowRightDrawer?.(true);
            handleClose?.();
        }
    };

    const { watch } = useFormContext<EventFormValues>();
    const rhfEvent = watch(EventFormFields.EVENT);

    useEffect(() => {
        const fetchEvent = async () => {
            if (eventId) {
                try {
                    const data = await getMeeting(eventId);
                    setEvent(data.meeting);
                } catch (error) {
                    console.error('Error fetching meeting:', error);
                }
            } else {
                setEvent(rhfEvent);
            }
        };

        fetchEvent();
    }, [eventId, rhfEvent]);

    const {
        addVenueIfCan,
        removeVenueIfCan,
        selectedVenues,
        loadingInquiry: isLoadingInquiry,
        setStagedVenue,
        stagedVenue,
        modalContent,
        inquiry,
        openConfirmationDialog,
        selectedVenueInquiry,
        modalNonPreferredContent,
    } = useVenueInquiry(event as Bizly.Event, { postInquiryHook: submitInquiryHook });

    const hasAcceptedProposal = inquiry?.venues.some(
        venue => venue.status === 'Booked' || venue.status === 'Contract Pending'
    );

    return (
        <>
            {!error && (
                <DialogTitle sx={{ m: 0, paddingInline: 3, paddingTop: 3, paddingBottom: 0 }}>
                    <Typography
                        color={EColors.black}
                        fontWeight={400}
                    >{`${i18n.venue.propertyId} #${venueId}`}</Typography>
                </DialogTitle>
            )}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    '&.MuiButtonBase-root': {
                        color: EColors.black,
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    },
                }}
            >
                <Close />
            </IconButton>

            {error ? (
                <VenueNotFound handleClose={handleClose} />
            ) : (
                <StyledDialogContent>
                    <VenueListing
                        addToInquiry={addVenueIfCan}
                        removeFromInquiry={removeVenueIfCan}
                        selectedVenues={selectedVenues}
                        useSearchParamId
                        onLoadingStatusChange={handleLoadingStatus}
                        isLoadingInquiry={isLoadingInquiry}
                        hasAcceptedProposal={hasAcceptedProposal}
                        onError={setError}
                    />
                </StyledDialogContent>
            )}
            {stagedVenue && (
                <ConfirmationModal
                    isActive
                    headline={i18n.venue.addToInquiry}
                    ctaLabel={i18n.button.add}
                    warning
                    onDismiss={() => setStagedVenue(undefined)}
                    {...modalContent()}
                />
            )}
            {selectedVenueInquiry && (
                <ConfirmationModal
                    isActive={openConfirmationDialog}
                    headline={i18n.communication.confirmation}
                    {...modalNonPreferredContent()}
                />
            )}
        </>
    );
};

export default VenueDetailModalContent;
