import { Box } from '@mui/material';
import { Button } from '../Button/Button';

interface DrawerActionButtonsProps {
    onCancel: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    isValid: boolean;
}

export default function DrawerActionButtons({ onCancel, onSubmit, isSubmitting, isValid }: DrawerActionButtonsProps) {
    return (
        <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="secondary" disableElevation onClick={onCancel}>
                Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid} onClick={onSubmit}>
                {isSubmitting ? 'Submitting...' : 'Save'}
            </Button>
        </Box>
    );
}
