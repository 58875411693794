import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { InsightsContainer } from '../InsightsContainer';
import {
    EVENT_SPACES_PATH,
    FAQS_PATH,
    GENERALINFO_PATH,
    GUEST_ROOMS_PATH,
    HIGHLIGHTS_PATH,
    PHOTOS_PATH,
} from './ListingsSubNavigation';

function ListingInsights() {
    const location = useLocation();

    let title = '';
    let descriptions: { text: string; fontWeight?: '400' | '500' | '600' }[] = [];

    const pathSegments = location.pathname.split('/');
    const CurrentPagePath = '/' + pathSegments[pathSegments.length - 1];

    switch (CurrentPagePath) {
        case GENERALINFO_PATH:
            title = 'First Impressions Matter';
            descriptions = [
                {
                    text: 'Your general information is the first thing planners read. Make it count! A well-crafted profile builds trust and encourages bookings.',
                },
                {
                    text: 'Listings with detailed descriptions receive 5x more inquiries.',
                    fontWeight: '500',
                },
            ];
            break;
        case PHOTOS_PATH:
            title = "Show, Don't Just Tell";
            descriptions = [
                {
                    text: 'Let your photos do the talking. Upload at least 12 high-resolution images to capture the unique character and versatility of your spaces.',
                },
                {
                    text: 'Listings with stunning visuals get 70% more requests.',
                    fontWeight: '500',
                },
            ];
            break;
        case HIGHLIGHTS_PATH:
            title = 'Emphasize the "Wow" Factor';
            descriptions = [
                {
                    text: 'Showcase what makes your venue unique and irresistible to event planners. Highlight your special features, amenities, and services to capture their attention.',
                },
                {
                    text: 'Showcasing unique experiences like outdoor spaces or activities can boost your bookings by 40%.',
                    fontWeight: '500',
                },
            ];
            break;
        case GUEST_ROOMS_PATH:
            title = 'Increase Overnight Bookings';
            descriptions = [
                {
                    text: 'Provide detailed information about your guest rooms to help planners make informed decisions for their attendees.',
                },
                {
                    text: 'Venues with detailed guest room descriptions and high-quality photos see a 60% increase in overnight bookings.',
                    fontWeight: '500',
                },
            ];
            break;
        case EVENT_SPACES_PATH:
            title = 'Spaces for Every Occasion';
            descriptions = [
                {
                    text: 'Bring your venue to life with detailed descriptions, photos, and floor plans of your event spaces.',
                },
                {
                    text: "Showcasing your venue's versatility can attract a wider range of events.",
                    fontWeight: '500',
                },
            ];
            break;
        case FAQS_PATH:
            title = 'Save time and Streamline Communication';
            descriptions = [
                {
                    text: "By answering common questions upfront, you'll streamline communication with planners and get those bookings rolling in faster.",
                },
                {
                    text: 'FAQs can boost your conversion rate by 15%.  Make it easy for planners to say "yes!" to your venue.',
                    fontWeight: '500',
                },
            ];
            break;
        default:
            break;
    }

    return <InsightsContainer title={title} descriptions={descriptions} />;
}

export default memo(ListingInsights);
