import { Box, styled } from '@mui/material';
import { TProposalForm } from 'components/ProposalForm/types';
import IataIcon from 'images/bizlyOS/icons/iataIcon.svg';
import { tzMoment } from 'utils/moment';
import { Body1, Body2 } from '../Typography/Typography';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    paddingTop: 0,
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const BorderedBottomContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing(2.5),
    paddingTop: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const InfoContainer = styled(BorderedContainer)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: spacing(1.25),
    padding: spacing(2.5),
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: 'none',
}));

const TextButton = styled(Box)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    color: getColor(EColors.bizlyOSPrimary),
    cursor: 'pointer',
}));

type Props = {
    proposalInquryForm: Partial<TProposalForm>;
};

export default function InquiryGuestRooms({ proposalInquryForm: { guestRooms, proposal } }: Props) {
    const totalRooms = guestRooms?.reduce((acc, { proposedRoomCount }) => acc + (proposedRoomCount ?? 0), 0);

    return (
        <Box display="flex" flexDirection="column" gap="10px">
            {proposal?.commissionable && (
                <InfoContainer>
                    <img src={IataIcon} alt="IATA Icon" />
                    <Box flex={1}>
                        <Body1 fontWeight={600}>Commissionable</Body1>
                        <Body1 fontWeight={600}>
                            IATA Number: <span style={{ fontWeight: '400' }}>33-7 4226 2</span>
                        </Body1>
                    </Box>
                    <TextButton>Terms</TextButton>
                </InfoContainer>
            )}
            <BorderedContainer>
                {guestRooms?.map(room => (
                    <BorderedBottomContainer>
                        <Body2>{tzMoment(room.date).format('MMM D, YYYY')}</Body2>
                        <Body2>{room.proposedRoomCount || 0} Rooms</Body2>
                    </BorderedBottomContainer>
                ))}
                <BorderedBottomContainer>
                    <Body2 fontWeight={500}>Total Room Nights</Body2>
                    <Body2 fontWeight={500}>{totalRooms} Rooms</Body2>
                </BorderedBottomContainer>
            </BorderedContainer>
        </Box>
    );
}
