import { Box } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { FieldError } from 'react-hook-form';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { Body2, Caption } from '../Typography/Typography';

const getCountry = () => {
    try {
        const Locale = Intl.Locale;
        return Locale ? new Locale(navigator.language).region?.toLowerCase() : undefined;
    } catch {
        return undefined;
    }
};

type PhoneFieldProps = {
    label: string;
    error: FieldError | undefined;
    onChange: (
        value: string,
        data: object | CountryData,
        event: ChangeEvent<HTMLInputElement>,
        formattedValue: string
    ) => void;
    onBlur: () => void;
    value: string | undefined;
    disabled?: boolean | undefined;
};

const PhoneField = React.forwardRef<HTMLDivElement, PhoneFieldProps>(({ label, error, value, onChange }) => {
    return (
        <Box>
            <Body2 fontWeight={500} marginBottom={1}>
                {label}
            </Body2>
            <PhoneInput
                value={value}
                onChange={onChange}
                country={getCountry()}
                specialLabel=""
                inputClass="phone-input"
            />
            {!!error && (
                <Caption color="red" fontWeight={500} marginBottom={1} size='large'>
                    {error.message}
                </Caption>
            )}
        </Box>
    );
});

export default PhoneField;
