import { Box, styled } from '@mui/material';
import { Body1 } from './Typography/Typography';

const CenteredBox = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'center',
    padding: '1rem',
    minHeight: '460px',
});

type EmptyMessageProps = {
    icon?: React.JSX.Element;
    action?: React.JSX.Element;
    title: string;
    description: string;
};

export default function EmptyMessage({ icon, title, description, action }: EmptyMessageProps) {
    return (
        <CenteredBox>
            {icon}
            <Box>
                <Body1 size="large" fontWeight={600} lineHeight="100%" textAlign="center" marginBottom={1.25}>
                    {title}
                </Body1>
                <Body1 width="450px" textAlign="center">
                    {description}
                </Body1>
            </Box>
            {action}
        </CenteredBox>
    );
}
