import Box from '@mui/material/Box';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { REJECTED_STATUSES } from 'components/VenueCard';
import VenueMap from 'components/VenueMap';
import { NoSearchGrid } from 'components/VenueSearch/VenueSearchInfiniteGrid';
import { useVenuesQuery } from 'hooks/queries/useVenuesQuery';
import { hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import { PaginatedVenuesGrid } from './PaginatedVenuesGrid';
import { sortAndStringify } from './SearchUtils';
import { TFilterValue } from './VenueSearchFilters';

const FittedVenueMap = styled(VenueMap)`
    flex: 0 0 calc(100% - 20px);
    max-width: 503px;
    min-width: 503px;
    max-height: 100vh;
    position: sticky;
    top: 0;
    margin-left: 20px;

    @media (max-width: 960px) {
        display: none;
    }
`;

type TPaginatedVenues = {
    event?: Bizly.Event;
    viewVenueListing: (venueId: number) => void;
    searchFilters: TFilterValue;
    latLng?: Bizly.Location;
    showMap: boolean;
    hideActions: boolean;
    selectedVenues?: { [venueId: number]: Bizly.Venue };
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
};

const PaginatedVenues = (props: TPaginatedVenues) => {
    const [highlightedId, setHighlightedId] = useState<number>();
    const [pinHighlightedId, setPinHighlightedId] = useState<number>();

    const { event, viewVenueListing, showMap, searchFilters, latLng, ...restProps } = props;

    const { venues: inquiryVenues } = useCurrentInquiry();
    const hasAnAcceptedProposal = hasAcceptedProposal(inquiryVenues ?? []);

    const [page, setPage] = useState(1);
    const {
        data: pageData,
        totalPages,
        isLoading,
    } = useVenuesQuery({
        filters: searchFilters,
        eventId: event?.id,
        latLng,
        page,
    });

    const prevQueryKey = useRef<string>('');
    useEffect(() => {
        const queryKey = sortAndStringify({ eventId: event?.id, ...searchFilters, ...latLng });
        if (queryKey !== prevQueryKey.current) {
            prevQueryKey.current = queryKey;
            setPage(1);
        }
    }, [searchFilters, latLng, event]);
    const venuesById = useMemo(() => (inquiryVenues ? keyBy(inquiryVenues, venue => venue.id) : {}), [inquiryVenues]);

    const resultsWithStatus = useMemo(() => {
        if (!pageData?.venues) return [];

        return pageData.venues.map(venue => {
            const inquiryVenue = venuesById[venue.id];
            if (inquiryVenue?.status && REJECTED_STATUSES.has(inquiryVenue.status)) {
                return { ...venue, status: inquiryVenue.status };
            }
            return venue;
        });
    }, [pageData, venuesById]);

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const onPinClick = useCallback(
        (venueId: number) => {
            setPinHighlightedId(venueId);
        },
        [setPinHighlightedId]
    );

    return (
        <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={4}>
            <Box>
                {resultsWithStatus.length > 0 && latLng && !isLoading ? (
                    <PaginatedVenuesGrid
                        data={resultsWithStatus}
                        onVenueHover={id => setHighlightedId(id || undefined)}
                        pinHighlightedVenue={pinHighlightedId}
                        onVisit={viewVenueListing}
                        hasAcceptedProposal={hasAnAcceptedProposal}
                        {...restProps}
                    />
                ) : (
                    <NoSearchGrid />
                )}
                {totalPages > 0 && (
                    <Box sx={{ pt: 5, pb: 2 }} justifyContent="center" display="flex">
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                    </Box>
                )}
            </Box>
            {showMap && (
                <FittedVenueMap
                    center={latLng}
                    venues={resultsWithStatus}
                    highlightedVenueId={highlightedId}
                    pinHighlightedVenueId={pinHighlightedId}
                    onPinClick={onPinClick}
                    showLegend
                />
            )}
        </Stack>
    );
};

export default PaginatedVenues;
