import { Box } from '@mui/material';
import GooglePlacesAutocomplete from 'components/BizlyOS/InputFields/PlaceInput';
import { Body1, Heading2 } from 'components/BizlyOS/Typography/Typography';
import SearchSvg from 'images/bizlyOS/coming-soon/calendar.svg';
import { useState } from 'react';
import { ClaimStepContainer, ClaimStepProps } from './ListingsClaim';

export default function VenueLocation(props: ClaimStepProps) {
    const [location, setLocation] = useState<string>(props.listingsClaimData.venueLocation?.name || '');
    const [placeId, setPlaceId] = useState<string>(props.listingsClaimData.venueLocation?.name || '');

    const onChange = (city: string, placeId: string) => {
        setLocation(city);
        setPlaceId(placeId);
    };

    const onSave = () => {
        props.onNextStepClicked({ venueLocation: { name: location, placeId }, venue: { name: '', placeId: '' } });
    };

    return (
        <ClaimStepContainer {...props} onSave={onSave} disabled={!location || !placeId}>
            <Box width="600px" boxSizing="border-box" height="100%" paddingTop="50px">
                <img src={SearchSvg} width={100} height={100} alt="Search Image" />
                <Heading2 marginBottom={1.25}>Let’s find your listing</Heading2>
                <Body1 size="large" marginBottom={2.5}>
                    First, where is your venue located?
                </Body1>
                <GooglePlacesAutocomplete locationValue={location} placeId={placeId} onChange={onChange} isCities />
            </Box>
        </ClaimStepContainer>
    );
}
