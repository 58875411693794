import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Snackbar, SnackbarCloseReason, styled } from '@mui/material';
import {
    DataGridPro,
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridCellCheckboxRenderer,
    GridColDef,
    GridHeaderCheckbox,
    GridRowParams,
} from '@mui/x-data-grid-pro';
import { Spinner } from 'components/Spinner';
import {
    useCreateGuestroomMutation,
    useGetGuestroomQuery,
    useGetGuestroomTypesQuery,
    useRemoveGuestroomMutation,
    useUpdateGuestroomMutation,
} from 'hooks/queries/BizlyOS/useListingQuery';
import EmptyTemplateImage from 'images/empty-pages-assets/empty_table.svg';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';
import * as z from 'zod';
import { Button } from '../Button/Button';
import ImageInputField from '../InputFields/ImageInputField';
import InputField from '../InputFields/InputField';
import RichTextEditorField from '../InputFields/RichTextEditorField';
import SelectField from '../InputFields/SelectField';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import TitleledContainer from '../TitleledContainer';
import { Body1, Body2, Heading5 } from '../Typography/Typography';

const IMAGES_LIMIT = 6;

const StyledDataGridPro = styled(DataGridPro)<DataGridProProps>(({ theme: { getColor, EColors } }) => ({
    flexGrow: 1,
    border: 'none',
    '& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader': {
        borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSGray20),
    },
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
}));

const CenteredBox = styled(Box)({
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    minHeight: '460px',
});

const StyledGridCellCheckboxRenderer = styled(GridCellCheckboxRenderer)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSBorder),
    '&.Mui-checked': {
        color: getColor(EColors.bizlyOSPrimary),
    },
}));

const StyledGridHeaderCheckbox = styled(GridHeaderCheckbox)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSBorder),
    '&.Mui-checked': {
        color: getColor(EColors.bizlyOSPrimary),
    },
}));

const ActionButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
}));

interface DrawerActionButtonsProps {
    onCancel: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    isValid: boolean;
}

const EmptyTasks = () => {
    return (
        <CenteredBox>
            <img src={EmptyTemplateImage} alt="Empty Image" />
            <Heading5 fontWeight={700} lineHeight="150%">
                Showcase Your Event Spaces
            </Heading5>
            <Body1 width="450px" textAlign="center">
                Showcase your comfortable and stylish guest rooms. From cozy singles to luxurious suites, let planners
                know about the accommodations you offer for their overnight guests.
            </Body1>
        </CenteredBox>
    );
};

const DrawerActionButtons = ({ onCancel, onSubmit, isSubmitting, isValid }: DrawerActionButtonsProps) => {
    return (
        <ActionButtonContainer>
            <Button variant="secondary" disableElevation onClick={onCancel}>
                Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid} onClick={onSubmit}>
                {isSubmitting ? 'Submitting...' : 'Save'}
            </Button>
        </ActionButtonContainer>
    );
};

export const AddGuestRoomSchema = z.object({
    typeId: z.number().min(1, 'Room Category is required'),
    description: z.string().min(8, 'Category Description is required'),
    roomCount: z.string().min(1, '# of Rooms in Category is required'),
    taxRate: z.string().min(1, 'Tax Rate is required'),
    images: z
        .array(
            z.object({
                id: z.string(),
                srcUrl: z.string(),
                name: z.string(),
            })
        )
        .min(1, 'Images are required'),
});

export type AddGuestRoomSchemaFormData = z.infer<typeof AddGuestRoomSchema>;

function AddGuestRoomDrawer({
    drawerOpen,
    setDrawerOpen,
    seletedGuestRoom,
    types = [],
    listingId,
    setSnackbarOpen,
}: {
    drawerOpen: boolean;
    setDrawerOpen: (toggle: boolean) => void;
    seletedGuestRoom: Bizly.GuestroomCategory | undefined;
    types: Bizly.GuestroomCategoryType[];
    isTypesLoading: boolean;
    listingId: string;
    setSnackbarOpen: (message: string) => void;
}) {
    const guestroomCreateMutation = useCreateGuestroomMutation(listingId, () => setSnackbarOpen('Successfully Added'));
    const guestroomUpdateMutation = useUpdateGuestroomMutation(listingId, seletedGuestRoom?.id?.toString() || '', () =>
        setSnackbarOpen('Successfully Updated')
    );

    const isAdd = !seletedGuestRoom;

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isValid, isSubmitting },
    } = useForm<AddGuestRoomSchemaFormData>({
        resolver: zodResolver(AddGuestRoomSchema),
        defaultValues: {
            typeId: 0,
            description: '',
            roomCount: '0',
            taxRate: '0',
            images: [],
        },
        mode: 'onChange',
    });

    const onSubmit = (data: AddGuestRoomSchemaFormData) => {
        const _data: Bizly.GuestroomCategory = {
            typeId: data.typeId,
            description: data.description,
            roomCount: parseInt(data.roomCount),
            taxRate: parseFloat(data.taxRate),
            images: data.images.map((image, index) => ({
                ...image,
                order: index,
                description: '',
            })),
        };

        if (isAdd) {
            guestroomCreateMutation.mutate(_data);
        } else {
            guestroomUpdateMutation.mutate(_data);
        }
        reset();
        setDrawerOpen(false);
    };

    const onClose = () => {
        setDrawerOpen(false);
        reset();
    };

    useEffect(() => {
        if (seletedGuestRoom) {
            setValue('typeId', seletedGuestRoom.type?.id || 0);
            setValue('description', seletedGuestRoom.description);
            setValue('roomCount', seletedGuestRoom.roomCount.toString());
            setValue('taxRate', seletedGuestRoom.taxRate.toString());

            const images = seletedGuestRoom.images.sort((a, b) => (a?.order || 0) - (b?.order || 0));

            const formattedImages = images.map(image => ({
                name: image?.name || '',
                id: `${image?.id || ''}`,
                srcUrl: image.srcUrl,
            }));

            setValue('images', formattedImages);
        }
    }, [setValue, seletedGuestRoom]);

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={onClose}
            footer={
                <DrawerActionButtons
                    onCancel={onClose}
                    onSubmit={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                />
            }
            title={isAdd ? 'Add Guest Room' : seletedGuestRoom.type?.name}
        >
            <Box display="flex" flexDirection="column" gap={2.5} height={'100%'} marginBottom={2.5}>
                <Controller
                    name="typeId"
                    control={control}
                    render={props => {
                        const { field } = props;
                        return (
                            <SelectField
                                {...field}
                                required
                                value={field.value || ''}
                                error={errors.typeId}
                                label="Room Category"
                                options={types.map(type => ({ label: type.name, value: type.id }))}
                            />
                        );
                    }}
                />

                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextEditorField
                                {...field}
                                required
                                error={errors.description}
                                label="Category Description"
                            />
                        );
                    }}
                />

                <Box display="flex" gap={2.5}>
                    <Box flex={1}>
                        <Controller
                            name="roomCount"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        type="number"
                                        required
                                        error={errors.roomCount}
                                        label="# of Rooms in Category"
                                    />
                                );
                            }}
                        />
                    </Box>
                    <Box flex={1}>
                        <Controller
                            name="taxRate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        type="number"
                                        required
                                        error={errors.taxRate}
                                        label="Tax Rate"
                                    />
                                );
                            }}
                        />
                    </Box>
                </Box>
                <Controller
                    name="images"
                    control={control}
                    render={({ field }) => {
                        return (
                            <ImageInputField
                                required
                                label="Room Photos"
                                limit={IMAGES_LIMIT}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.images}
                                listingId={listingId}
                            />
                        );
                    }}
                />
            </Box>
        </SideDrawer>
    );
}

const columns: GridColDef[] = [
    {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: params => <StyledGridCellCheckboxRenderer {...params} />,
        renderHeader: params => <StyledGridHeaderCheckbox {...params} />,
    },
    {
        field: 'type',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Room Category
            </Body2>
        ),
        flex: 1,
        minWidth: 100,
        renderCell: params => {
            const images = params.row.images.sort(
                (a: Bizly.VenueImage, b: Bizly.VenueImage) => (a?.order || 0) - (b?.order || 0)
            );
            return (
                <Box display="flex" alignItems="center" padding="10px" gap="20px">
                    <img src={images[0].srcUrl} style={{ width: 60, height: 60, borderRadius: 4 }} />
                    <Body2 fontWeight={500} lineHeight="100%">
                        {params.value.name}
                    </Body2>
                </Box>
            );
        },
    },
    { field: 'roomCount', headerName: 'Quantity', width: 150 },
    {
        field: 'updatedAt',
        headerName: 'Last Updated',
        width: 150,
        valueGetter: () => {
            return tzMoment(new Date()).format('MMM D, YYYY');
        },
    },
];

export default function ListingGuestRooms({ listing }: { listing: Bizly.Venue }) {
    const { data: guestrooms, isLoading: isGuestroomsLoading } = useGetGuestroomQuery(listing.id.toString());
    const { data: guestroomTypes, isLoading: isGuestroomTypesLoading } = useGetGuestroomTypesQuery();
    const removeGuestroomMutation = useRemoveGuestroomMutation(listing.id.toString(), () =>
        setSnackbarOpen('Successfully Deleted')
    );

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [seletedGuestRoom, setSeletedGuestRoom] = useState<Bizly.GuestroomCategory>();
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleRowClick = (params: GridRowParams<Bizly.GuestroomCategory>) => {
        setSeletedGuestRoom(params.row);
        setDrawerOpen(true);
    };

    const handleSelectionChange = (selectionModel: number[]) => {
        setSelectedRowIds(selectionModel);
    };

    const clearSelection = () => setSelectedRowIds([]);

    const handleDelete = async () => {
        removeGuestroomMutation.mutateAsync(selectedRowIds);
        clearSelection();
    };

    return (
        <>
            <TitleledContainer
                title={selectedRowIds.length > 0 ? selectedRowIds.length + ' Guest Rooms selected' : 'Guest Rooms'}
                rightElement={
                    selectedRowIds.length > 0 ? (
                        <Box display="flex" gap={1.25}>
                            <Button variant="outline" sx={{ height: 40 }} onClick={clearSelection}>
                                Cancel
                            </Button>
                            <Button
                                disabled={removeGuestroomMutation.isLoading}
                                sx={{ height: 40 }}
                                onClick={handleDelete}
                                color="error"
                            >
                                {removeGuestroomMutation.isLoading ? 'Deleting...' : 'Delete'}
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            sx={{ padding: '4px' }}
                            variant="text"
                            onClick={() => {
                                setDrawerOpen(true);
                                setSeletedGuestRoom(undefined);
                            }}
                        >
                            + Add New Room
                        </Button>
                    )
                }
                bodyPadding={0}
            >
                {isGuestroomsLoading || !guestrooms ? (
                    <Spinner />
                ) : guestrooms.length > 0 ? (
                    <StyledDataGridPro
                        columns={columns}
                        rows={guestrooms}
                        onRowClick={handleRowClick}
                        loading={isGuestroomsLoading}
                        rowHeight={80}
                        hideFooter
                        disableRowSelectionOnClick
                        checkboxSelection
                        rowSelectionModel={selectedRowIds}
                        onRowSelectionModelChange={handleSelectionChange}
                    />
                ) : (
                    <EmptyTasks />
                )}
            </TitleledContainer>
            <AddGuestRoomDrawer
                listingId={listing.id.toString()}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                seletedGuestRoom={seletedGuestRoom}
                types={guestroomTypes ? guestroomTypes : []}
                isTypesLoading={isGuestroomTypesLoading}
                setSnackbarOpen={setSnackbarOpen}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{
                    '&.MuiSnackbar-root': { top: '65px', right: '20px' },
                }}
            >
                <Alert
                    severity="success"
                    icon={false}
                    variant="filled"
                    sx={{
                        width: '100%',
                        backgroundColor: getColor(EColors.bizlyOSPrimary),
                    }}
                    onClose={handleSnackbarClose}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );

    function handleSnackbarClose(_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function setSnackbarOpen(message: string) {
        setSnackbarMessage(message);
        setOpenSnackbar(true);
    }
}
