import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Popper, PopperProps, styled } from '@mui/material';
import Autocomplete, {
    AutocompleteRenderOptionState,
    AutocompleteValue,
    autocompleteClasses,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { findCities, findPlaces } from 'api';
import LocationIcon from 'images/bizlyOS/icons/location.svg?react';
import EmptyIcon from 'images/empty-pages-assets/empty_table.svg';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Body1, Body2 } from '../Typography/Typography';

const Input = styled(TextField)(({ theme }) => ({
    '.MuiOutlinedInput-notchedOutline': {
        borderRadius: theme.spacing(0.5),
    },
}));

const OptionBox = styled(Box)(({ theme: { getColor, EColors }, selected, borderBottom, height = '44px' }) => ({
    height,
    margin: '5px',
    [`&.${autocompleteClasses.option}.${autocompleteClasses.focused}`]: {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    },
    [`&.${autocompleteClasses.option}`]: {
        backgroundColor: selected ? getColor(EColors.bizlyOSPrimary, 0.1) : 'transparent',
        borderBottom: borderBottom ? '1px solid ' + getColor(EColors.bizlyOSBorder) : 'none',
        borderRadius: borderBottom ? 'none' : '4px',
    },
}));

type TOption = {
    description: string;
    placeId: string;
    types?: string[];
    suggestion?: string;
    mainText?: string;
    secondaryText?: string;
};

type TPlaceInput<TInputAsSuggestion extends boolean | undefined> = {
    locationBias?: Bizly.Location;
    clearable?: boolean;
    onRemove?: () => void;
    directVenueLinking?: boolean;
    locationValue?: string;
    placeId?: string;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    isCities?: boolean;
    noOptionIcon?: boolean;
    suggestionText?: string;
} & (TInputAsSuggestion extends true
    ? {
          inputAsSuggestion: true;
          onChange: (city: string, googlePlaceId?: string | undefined) => void;
      }
    : {
          inputAsSuggestion?: false | undefined;
          onChange: (city: string, googlePlaceId: string) => void;
      });

const GooglePlacesAutocomplete = (props: TPlaceInput<true> | TPlaceInput<false>) => {
    const {
        locationBias,
        onChange,
        clearable,
        onRemove,
        inputAsSuggestion,
        suggestionText,
        locationValue,
        placeId,
        disabled,
        placeholder,
        required,
        isCities = false,
        noOptionIcon = false,
    } = props;

    const [inputQuery, setInputQuery] = useState<string>('');
    const [options, setOptions] = useState<TOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [noOptionsText, setNoOptionsText] = useState(i18n.venue.noLocationsForQuery(inputQuery ?? ''));

    const inputOption = useMemo(
        () => ({
            description: locationValue ?? '',
            placeId: placeId ?? '',
            types: undefined,
        }),
        [locationValue, placeId]
    );

    const resetOptions = () => {
        setLoading(false);
        setOptions([]);
    };

    const handleSelection = (city: string, placeId: string) => {
        setInputQuery(city);
        onChange?.(city, placeId);
    };

    function handleClear() {
        resetOptions();
        if (clearable) onRemove?.();
    }

    const onInputChange = (_: React.SyntheticEvent, newInputQuery: string) => {
        setInputQuery(newInputQuery);
        if (!newInputQuery) {
            handleClear();
        }
    };

    const onSelectionChange = (e: React.SyntheticEvent, val: AutocompleteValue<TOption, false, false, true>) => {
        if (!e || !val || typeof val === 'string') {
            handleClear();
            return;
        }
        handleSelection(val.description, val.placeId);
    };

    const getPlaces = useMemo(
        () =>
            debounce(async (query: string) => {
                setNoOptionsText(i18n.venue.noLocationsForQuery(query || ''));
                if (query.length > 0) {
                    setLoading(true);
                    try {
                        const response = await findPlaces(query, locationBias?.lat, locationBias?.lng, true);
                        if (response.status === 'OK') {
                            const predictions = response.predictions.map(
                                (prediction: BizlyAPI.AutocompletePrediction) => ({
                                    description: prediction.description,
                                    placeId: prediction.placeId,
                                    mainText: prediction.structuredFormatting.mainText,
                                    secondaryText: prediction.structuredFormatting.secondaryText,
                                })
                            );
                            setOptions(predictions);
                        } else {
                            resetOptions();
                        }
                    } catch {
                        setNoOptionsText(i18n.error.default);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    resetOptions();
                }
            }, 300),
        [locationBias]
    );

    const getCities = useMemo(
        () =>
            debounce(async (query: string) => {
                if (query.length > 0) {
                    setLoading(true);
                    try {
                        const response = await findCities(query);
                        if (response.status === 'OK') {
                            setOptions([...response.predictions]);
                        } else {
                            resetOptions();
                        }
                    } catch {
                        setNoOptionsText(i18n.error.default);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    resetOptions();
                }
            }, 300),
        []
    );

    useEffect(() => {
        if (!inputQuery) {
            setOptions([]);
            return;
        }

        // Call the debounced function whenever the input value changes
        if (isCities) {
            getCities(inputQuery);
        } else {
            getPlaces(inputQuery);
        }

        // Cleanup function to cancel any pending debounced calls on component unmount
        return () => {
            getPlaces.cancel();
            getCities.cancel();
        };
    }, [inputQuery, getPlaces, getCities]);

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TOption,
        state: AutocompleteRenderOptionState
    ) => {
        const { 'aria-selected': _, ...optionProps } = props;
        return (
            <OptionBox
                {...optionProps}
                component="li"
                key={option.placeId}
                selected={state.selected}
                borderBottom={!!option.suggestion}
                height={option.mainText ? '64px' : !!option.suggestion ? '54px' : '44px'}
            >
                {!option.suggestion ? (
                    <Box display="flex" alignItems="center" gap="10px">
                        {!noOptionIcon && <LocationIcon />}
                        {option.mainText ? (
                            <Box>
                                <Body1 fontWeight={600} paddingTop={1.25}>
                                    {option.mainText}
                                </Body1>
                                <Body2 color={EColors.tagColor} paddingBottom={1.25}>
                                    {option.secondaryText}
                                </Body2>
                            </Box>
                        ) : (
                            <>
                                <Body2 fontWeight={500}>{option.description}</Body2>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box display="flex" alignItems="center" gap="10px">
                        <img src={EmptyIcon} width={44} height={44} />
                        <Box flex={1}>
                            <Body1 fontWeight={600}>{option.description}</Body1>
                            <Body2 color={EColors.tagColor} paddingBottom={1.25}>
                                {option.suggestion}
                            </Body2>
                        </Box>
                    </Box>
                )}
            </OptionBox>
        );
    };

    const AutocompletePopper = function (props: PopperProps) {
        return (
            <Popper
                {...props}
                disablePortal
                open={true}
                sx={{
                    width: '600px',
                    paddingTop: '4px',
                    '& .MuiAutocomplete-paper': {
                        padding: 0,
                        boxShadow: 'none',
                        border: '1px solid #DBDBDB',
                        borderRadius: '4px',
                    },
                    '& ul': {
                        padding: '5px 0px',
                    },
                    boxShadow: 'none',
                }}
            />
        );
    };

    const getFinalOptions = () => {
        const inputSuggestionOption = {
            description: inputQuery,
            placeId: '',
            // suggestion: 'Apply to list a venue with this name',
            suggestion: suggestionText || '',
        };

        let finalOptions: TOption[] = [];
        if (options.length > 0) {
            if (inputAsSuggestion && options[0].description !== inputSuggestionOption.description) {
                finalOptions = [inputSuggestionOption];
            }
            finalOptions = [...finalOptions, ...options];
        } else {
            if (inputAsSuggestion && inputQuery) {
                finalOptions = [inputSuggestionOption];
            }
        }
        return finalOptions;
    };

    return (
        <Autocomplete
            freeSolo
            disabled={disabled}
            loading={loading}
            disableClearable={!clearable}
            options={getFinalOptions()}
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            noOptionsText={noOptionsText}
            value={inputOption}
            isOptionEqualToValue={(option: TOption, value: TOption) => option.description === value.description}
            filterOptions={x => x}
            onInputChange={onInputChange}
            onChange={onSelectionChange}
            renderOption={renderOption}
            PopperComponent={AutocompletePopper}
            renderInput={params => (
                <Input
                    {...params}
                    placeholder={placeholder ?? `${i18n.homepage.createMeetingModal.find} (required)`}
                    variant="outlined"
                    fullWidth
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default GooglePlacesAutocomplete;
