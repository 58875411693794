import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import OutlinedInput from '@mui/material/OutlinedInput';
import GooglePlacesAutocomplete from 'components/Ui-V2/AutoComplete/PlaceInput';
import { i18n } from 'translation';
import { useEvent } from '../../providers/event';
import { queryParamsToPlace } from './SearchUtils';

type TProps = {
    disabled: boolean;
    onSearch: (locationName: string, placeId: string) => void;
    value: string;
};

const VenueSearchField = ({ disabled, onSearch, value }: TProps) => {
    const location = useLocation();
    const [guestCount, setGuestCount] = useState<number>(); // TODO: include guest number in the url

    // transparentHeader is visible from venue listing
    const { event } = useEvent();

    // if location changes, we sync our internal value with location
    // we store our own because it allows us to control the input without doing a submit
    const q = useMemo(() => queryParamsToPlace(location.search)?.q, [location.search]);

    const locationBias = useMemo(
        () => (event.lat && event.lng ? { lat: event.lat, lng: event.lng } : undefined),
        [event.lat, event.lng]
    );

    const handlePlaceInputChange = (city: string, placeId: string) => {
        onSearch(city, placeId);
    };

    const handleClear = () => {
        onSearch('', '');
    };

    return (
        <Box display="flex" alignItems="center" gap={2} flex={0.5}>
            <Box flex={1}>
                <GooglePlacesAutocomplete
                    locationValue={value || q}
                    onChange={handlePlaceInputChange}
                    onRemove={handleClear}
                    locationBias={locationBias}
                    disabled={disabled}
                    placeholder={i18n.venue.searchPlaceholder}
                    directVenueLinking={true}
                    clearable
                />
            </Box>
            {
                /* temporarily hide until we redesign the venue detail page */
                false && <DatePicker />
            }
            {
                /* temporarily hide until we redesign the venue detail page */
                false && (
                    <OutlinedInput
                        id="guest-number"
                        type="number"
                        value={String(guestCount ?? 0)}
                        onChange={e => setGuestCount(Number(e.target.value))}
                        placeholder="Guests"
                    />
                )
            }
        </Box>
    );
};

export default VenueSearchField;
