import { addVenueToInquiryDraft, getVenueInquiries, removeVenueFromInquiry } from 'api';
import { getCurrentInquiry } from 'api/inquiry';
import { SpinnerOverlay } from 'components/Spinner';
import keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntercomAPI } from 'react-intercom';
import { useNavigate } from 'react-router-dom';
import { currentInquiryActions } from 'stores/current-inquiry';
import { i18n } from 'translation';
import { Spacer, Copy, ExternalLink, Row } from 'ui';
import { MAX_INQUIRY_VENUE_SLOTS, getActiveVenuesCount, getSelectedVenueInqs } from 'utils/venue';
import { useManageUrlParams } from './venueSearchHooks';
import { Box, Button } from '@mui/material';

type UseVenueInquiryOptionsType = {
    postInquiryHook?: (inquiry?: Bizly.Venue[] | []) => void;
};

const useVenueInquiry = (event: Bizly.Event | undefined, options: UseVenueInquiryOptionsType = {}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { filters, setFilters } = useManageUrlParams(event);

    const { postInquiryHook } = options;

    const [stagedVenue, setStagedVenue] = useState<Bizly.Venue | undefined>();
    const [updatingVenues, setUpdatingVenues] = useState(false);
    const [currentVenues, setCurrentVenues] = useState<Bizly.Venue[]>([]);
    const [inquiry, setInquiry] = useState<BizlyAPI.Inquiry | null>();
    const [loadingInquiry, setLoadingInquiry] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedVenueInquiry, setSelectedVenueInquiry] = useState<Bizly.Venue | undefined>();

    useEffect(() => {
        if (!event?.id) return;

        const fetchVenueInquiries = async () => {
            setLoadingInquiry(true);
            const inquiry = await getCurrentInquiry(event.id);
            setInquiry(inquiry);
            if (inquiry) {
                const eventInquiryVenues = await getVenueInquiries(event.id);
                setCurrentVenues(eventInquiryVenues);
            }
            setLoadingInquiry(false);
        };

        fetchVenueInquiries();
    }, [event?.id, updatingVenues]);

    const venueBooked = useMemo(() => {
        return currentVenues.some((venue: Bizly.Venue) => venue.status === 'Booked') ?? false;
    }, [currentVenues]);

    const canSubmitInquiry = !venueBooked && event?.editable && !event?.cancelledAt;
    const isDraft = inquiry?.draft;
    const isSubmitted = inquiry?.submittedAt;

    const availableSlots = useMemo(
        () => (!currentVenues || venueBooked ? 0 : MAX_INQUIRY_VENUE_SLOTS - getActiveVenuesCount(currentVenues)),
        [venueBooked, currentVenues]
    );

    const addedVenuesCount = useMemo(
        () =>
            !currentVenues
                ? 0
                : currentVenues.filter(venueInquiry => 'status' in venueInquiry && venueInquiry.status === 'Added')
                      .length,
        [currentVenues]
    );

    const openSlots = useMemo(() => Math.max(availableSlots - addedVenuesCount, 0), [availableSlots, addedVenuesCount]);

    const selectedVenues = useMemo(() => {
        if (venueBooked) return {};
        return keyBy(
            getSelectedVenueInqs(currentVenues || []).map((venueInq: Bizly.Venue) => venueInq.venue as Bizly.Venue),
            'id'
        );
    }, [venueBooked, currentVenues]);

    const addVenueConfirm = useCallback((venue: Bizly.Venue) => setStagedVenue(venue), []);

    const addVenue = useCallback(
        async (venue: Bizly.Venue) => {
            if (!event?.id || openSlots <= 0 || selectedVenues[venue.id]) return;

            setUpdatingVenues(true);

            try {
                const { inquiry: inquiryDraft } = await addVenueToInquiryDraft(event.id, {
                    inquiryId: inquiry?.id ?? undefined,
                    venueId: venue.id,
                });

                if (isSubmitted) {
                    currentInquiryActions.mergeVenues(event.id, [{ ...venue, venue: venue, status: 'Submitted' }]);
                    navigate(`/event/${event.id}/venue/inquiries`);
                } else {
                    currentInquiryActions.setInquiry(event.id, inquiryDraft);
                    currentInquiryActions.mergeVenues(event.id, [{ ...venue, venue: venue, status: 'Added' }]);
                    postInquiryHook?.(currentVenues);
                }

                setOpenConfirmationDialog(false);
            } catch {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            } finally {
                setUpdatingVenues(false);
            }
            setStagedVenue(undefined);
        },
        [openSlots, selectedVenues, event?.id, inquiry?.id, isSubmitted, navigate, enqueueSnackbar]
    );

    const addVenueNonPreferredConfirm = (venue: Bizly.Venue) => {
        if (!venue.preferred) {
            setOpenConfirmationDialog(true);
            setSelectedVenueInquiry(venue);
        } else {
            addVenue(venue);
        }
    };

    const removeVenue = useCallback(
        async (venue: Bizly.Venue) => {
            if (!inquiry?.id || !event?.id) return;

            setUpdatingVenues(true);

            try {
                const { inquiry: inquiryRemove } = await removeVenueFromInquiry(inquiry?.id, venue.id);

                currentInquiryActions.setInquiry(event.id, inquiryRemove);
                currentInquiryActions.removeVenueInq(event.id, venue.id);
            } catch {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            } finally {
                setUpdatingVenues(false);
            }
        },
        [inquiry?.id, event?.id, enqueueSnackbar]
    );

    const addVenueIfCan = useCallback(
        (venue: Bizly.Venue) =>
            openSlots > 0 && canSubmitInquiry && !updatingVenues
                ? isSubmitted || venue.tempClosed
                    ? addVenueConfirm(venue)
                    : addVenueNonPreferredConfirm(venue)
                : undefined,
        [openSlots, canSubmitInquiry, updatingVenues, isSubmitted, addVenueConfirm, addVenue]
    );

    const removeVenueIfCan = useMemo(
        () =>
            Object.keys(selectedVenues).length > 0 && canSubmitInquiry && !updatingVenues && isDraft
                ? removeVenue
                : undefined,
        [selectedVenues, canSubmitInquiry, updatingVenues, isDraft, removeVenue]
    );

    const modalContent = () => {
        if (stagedVenue?.tempClosed) {
            return {
                prompt: (
                    <>
                        <Copy>
                            {i18n.venue.closeCopy + ' '}
                            <ExternalLink onClick={() => IntercomAPI('show')}>{i18n.venue.contactSupport}</ExternalLink>
                            .
                        </Copy>
                        {updatingVenues && <SpinnerOverlay />}
                    </>
                ),
            };
        }

        return {
            prompt: (
                <>
                    <span>
                        {i18n.venue.inquirySubmissionHelper}
                        <Spacer small />
                        {i18n.venue.inquirySubmissionConfirm}
                    </span>
                    {updatingVenues && <SpinnerOverlay />}
                </>
            ),
            onProceed: () => addVenue(stagedVenue!),
        };
    };

    const modalNonPreferredContent = () => {
        return {
            prompt: (
                <>
                    <Box>
                        {i18n.venue.nonPreferredVenueConfirmation}
                        <Spacer smallish />
                        {i18n.venue.addToVenueQueue}
                    </Box>
                    <Spacer large />
                    <Row itemSpacing="smallish" justifyContent="center">
                        <Button
                            variant="outlined"
                            sx={{
                                width: '50%',
                                '&.MuiButton-containedPrimary': {
                                    marginRight: 0,
                                },
                            }}
                            onClick={(e: React.MouseEvent) =>  {
                                e.stopPropagation();
                                setOpenConfirmationDialog(false);
                            }}
                        >
                            No, keep searching
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: '50%',
                                '&.MuiButton-containedPrimary': {
                                    marginRight: 0,
                                },
                            }}
                            onClick={() => addVenue(selectedVenueInquiry as Bizly.Venue)}
                        >
                            Yes, add it
                        </Button>
                    </Row>
                    <Spacer small />
                    <Row itemSpacing="smallish" justifyContent="center">
                        <Button
                            variant="text"
                            sx={{
                                width: '100%',
                                '&.MuiButton-textPrimary': {
                                    marginRight: 0,
                                },
                            }}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setFilters({
                                    ...filters,
                                    preferredOnly: true,
                                });
                                setOpenConfirmationDialog(false);
                            }}
                        >
                            Show me preferred in the area
                        </Button>
                    </Row>
                </>
            )
        };
    };

    return {
        venueBooked,
        stagedVenue,
        setStagedVenue,
        updatingVenues,
        setUpdatingVenues,
        availableSlots,
        addedVenuesCount,
        openSlots,
        selectedVenues,
        addVenueConfirm,
        addVenue,
        removeVenue,
        addVenueIfCan,
        removeVenueIfCan,
        modalContent,
        loadingInquiry,
        inquiry,
        openConfirmationDialog,
        setOpenConfirmationDialog,
        selectedVenueInquiry,
        setSelectedVenueInquiry,
        addVenueNonPreferredConfirm,
        modalNonPreferredContent,
    };
};

export default useVenueInquiry;
