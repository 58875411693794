import { TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ResourcesList from 'components/Playbook/ResourcesList';
import { BLANK_TEMPLATE_DEFAULT_NAME } from 'constants/templates';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Copy } from 'ui';
import { getShortTimeZone } from 'utils/date_util';
import { tzMoment } from 'utils/moment';

const ItemLabel = styled(Copy)`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
    display: inline-flex;
    gap: 3px;
`;
const ItemContent = styled(AlignedRow)`
    font-size: 1rem;
    font-weight: normal;
    margin-top: 8px;
    border: none;
    padding: 0;
    border-radius: 5px;
    box-sizing: border-box;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const StyledContainer = styled(Grid)`
    padding: 0px 0 30px;
`;

const StyledTextField = styled(TextField)`
    & {
        width: 100%;
    }

    & .MuiInputBase-root {
        padding-left: 0;
    }

    & .MuiInputBase-input {
        font-size: 1rem;
        cursor: default;
        padding: 0;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }

    & fieldset {
        border: none;
        padding-inline: 0;
    }
`;

type TDetailItem = {
    label: string;
    value: ReactNode;
    isRequired?: boolean;
};

export const DetailItem = ({ label, value, isRequired }: TDetailItem) => (
    <div>
        <ItemLabel>
            {label}
            {isRequired && (
                <Typography fontSize="14px" component="span" color="grey">
                    ({i18n.common.required})
                </Typography>
            )}
        </ItemLabel>
        <ItemContent>{value}</ItemContent>
    </div>
);

type TEventDetailsProps = {
    cventName: string;
};

const EMPTY_VALUE = 'N/A';

export default function EventDetails({ cventName }: TEventDetailsProps) {
    const { user } = useUser();
    const { event, template } = useEvent();

    const shouldRenderTemplateRow =
        template.name && template.name.toLowerCase() !== BLANK_TEMPLATE_DEFAULT_NAME.toLowerCase();

    const timeZoneAbbreviation = getShortTimeZone(event.startsAt || new Date().toISOString(), event.timeZone || 'UTC');

    return (
        <StyledContainer container spacing={3}>
            <Grid item xs={12} sm={6}>
                <DetailItem
                    isRequired
                    label={i18n.homepage.createMeetingModal.eventName}
                    value={event.name || EMPTY_VALUE}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DetailItem label={i18n.meetingsPage.cventEvent} value={cventName} />
            </Grid>

            <Grid item xs={12}>
                <DetailItem
                    label={i18n.meetingsPage.eventDescription}
                    value={<div dangerouslySetInnerHTML={{ __html: event.description || EMPTY_VALUE }} />}
                />
            </Grid>

            <Grid item xs={12}>
                <DetailItem
                    isRequired
                    label={i18n.homepage.createMeetingModal.location}
                    value={event.location ?? EMPTY_VALUE}
                />
            </Grid>

            <Grid item xs={12} sm={6} gap="8px" display="grid">
                <ItemLabel>{i18n.meetingsPage.startDate}</ItemLabel>
                <StyledTextField
                    value={event.startsAt ? tzMoment(event.startsAt).format('ll') : EMPTY_VALUE}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                />
            </Grid>

            <Grid item xs={12} sm={6} gap="8px" display="grid">
                <ItemLabel>{i18n.meetingsPage.startTime}</ItemLabel>
                <StyledTextField
                    value={
                        event.startsAt
                            ? `${tzMoment(event.startsAt).format('LT')} ${timeZoneAbbreviation}`
                            : EMPTY_VALUE
                    }
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                />
            </Grid>

            <Grid item xs={12} sm={6} gap="8px" display="grid">
                <ItemLabel>{i18n.meetingsPage.endDate}</ItemLabel>
                <StyledTextField
                    value={event.endsAt ? tzMoment(event.endsAt).format('ll') : EMPTY_VALUE}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                />
            </Grid>
            <Grid item xs={12} sm={6} gap="8px" display="grid">
                <ItemLabel>{i18n.meetingsPage.endTime}</ItemLabel>
                <StyledTextField
                    value={
                        event.endsAt ? `${tzMoment(event.endsAt).format('LT')} ${timeZoneAbbreviation}` : EMPTY_VALUE
                    }
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                />
            </Grid>

            <Grid item xs={12} sm={6} gap="8px" display="grid">
                <ItemLabel>
                    {event.budget
                        ? i18n.meetingsPage.totalBudget
                        : event.budgetPerPerson
                          ? i18n.meetingsPage.perPersonBudget
                          : i18n.meetingsPage.budget}
                </ItemLabel>
                <StyledTextField
                    value={`$${event.budget ?? event.budgetPerPerson ?? EMPTY_VALUE}`}
                    inputProps={{ readOnly: true }}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DetailItem
                    label={user.team?.meetingInternalReferenceFieldLabel ?? i18n.meetingsPage.internalReference}
                    value={String(event.cventId ?? EMPTY_VALUE)}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DetailItem
                    label={user.team?.meetingCostCenterFieldLabel ?? i18n.meetingsPage.costCenter}
                    value={event.costCenter ?? EMPTY_VALUE}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DetailItem label={i18n.meetingsPage.department} value={String(event.department ?? EMPTY_VALUE)} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DetailItem label={i18n.meetingsPage.meetingType} value={event.type ?? EMPTY_VALUE} />
            </Grid>

            <Grid item xs={12} sm={12}>
                <ResourcesList
                    playbookId={template.id as string | number}
                    readonly
                />
            </Grid>

            {shouldRenderTemplateRow && (
                <Grid item xs={12} sm={6}>
                    <DetailItem
                        label={i18n.meetingsPage.template}
                        value={template.name ?? i18n.meetingsPage.noTemplate}
                    />
                </Grid>
            )}
        </StyledContainer>
    );
}
