import { Spinner } from 'components/Spinner';
import { LoadPlaybookResources, playbooksActions, selectResources, usePlaybooks } from 'stores/playbooks';
import { i18n } from 'translation';
import { AlignedRow, Column, Copy, ExternalLink } from 'ui';
import styled from 'styled-components';
import UploadBox from 'components/Ui-V2/UploadBox/UploadBox';

const LinkItem = styled.div`
    padding: 0.5em;
    border-radius: 4px;
    width: 100%;
`;

const ItemLabel = styled(Copy)`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
    display: inline-flex;
    gap: 3px;
`;

const ItemContent = styled(AlignedRow)`
    font-size: 1rem;
    font-weight: normal;
    border: none;
    padding: 0;
    border-radius: 5px;
    box-sizing: border-box;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const ResourcesList = ({ playbookId, readonly }: { playbookId: string | number; readonly?: boolean }) => {
    const playbookResources = usePlaybooks(selectResources(playbookId));

    const handleAddResource = (files: File[]) => {
        if (!playbookId) return;
        files.forEach(file => {
            const document: Bizly.EventResource = {
                id: 0,
                title: file.name,
                description: undefined,
                url: URL.createObjectURL(file),
                cloudinaryId: ''
            };
            playbooksActions.addPlaybookResources(playbookId, document);
        });
    };

    if (!playbookId || !playbookResources)
        return (
            <>
                <LoadPlaybookResources id={playbookId} />
                <Spinner />
            </>
        );

    return (
        <Column itemSpacing="small">
            <LoadPlaybookResources id={playbookId} />
            <ItemLabel>{i18n.playbookWizard.collaboration.documents}</ItemLabel>
            {!readonly && (
                <UploadBox
                    files={playbookResources.map((doc) => new File([doc.url], doc.title))}
                    target={`playbook-${playbookId}`}
                    onUpload={handleAddResource}
                    link={
                        playbookResources.length > 0
                            ? playbookResources.map((doc) => ({
                                    id: doc.id,
                                    title: doc.title,
                                    url: doc.url,
                                    playbookId: playbookId,
                                }))
                            : []
                    }
                    isResourceFile={true}
                />
            )}
            {readonly && playbookResources.length === 0 && (
                <ItemContent>{i18n.playbookWizard.collaboration.noDocumentsUploaded}</ItemContent>
            )}
            {readonly &&
                playbookResources.map((document, index) => (
                    <LinkItem key={`${document.url}_${index}`}>
                        <ExternalLink href={document.url} openInNewTab>
                            {document.title}
                        </ExternalLink>
                    </LinkItem>
                ))}
        </Column>
    );
};

export default ResourcesList;
