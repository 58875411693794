import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { getVenue } from 'api';
import { Body1, Body2, Heading2 } from 'components/BizlyOS/Typography/Typography';
import { RowContainer } from 'components/BizlyOS/GridContainer';
import TaskDashboard from 'components/BizlyOS/Home/TaskDashboard';
import { ImageWithSkeleton } from 'components/BizlyOS/Image/ImageWithSkeleton';
import TeamWorkImage from 'images/bizlyOS/coming-soon/team-work.svg';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useUser } from 'providers/user';
import { useEffect, useState } from 'react';
import styledComponents from 'styled-components';
import { Row } from 'ui';

const BorderedContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

TipsAndUpdatesOutlinedIcon;

const TipsAndUpdatesIcon = styled(TipsAndUpdatesOutlinedIcon)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
    fontSize: '1.875rem',
}));

const LeftSection = styled(BorderedContainer)(() => ({
    flex: 1,
    alignItems: 'center',
}));

const SectionHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '50px',
    boxSizing: 'border-box',
    width: '100%',
}));

const SectionBody = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    gap: theme.spacing(2.5),
    overflowY: 'auto',
    maxWidth: 1400,
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius * 2,
}));

const SecondaryContainer = styled(Container)(({ theme: { getColor, EColors, spacing } }) => ({
    backgroundColor: getColor(EColors.drWhite),
    gap: spacing(1.25),
}));

const PrimaryContainer = styled(Container)(({ theme: { getColor, EColors, spacing } }) => ({
    flexDirection: 'column',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    gap: spacing(1.25),
}));

const OutLineContainer = styled(Container)(({ theme }) => ({
    alignItems: 'flex-start',
    gap: theme.spacing(1.25),
    border: `1px solid ${theme.palette.divider}`,
}));

const StyledUl = styledComponents.ul`
  margin: 0;
  padding-left: 20px;
`;

const StyledLi = styledComponents.li`
  padding: 5px 0px;
`;

export function Home() {
    const { user } = useUser();
    const { venueId } = useBizlyOSUser();

    const [propertyName, setPropertyName] = useState<string>('Property Name');

    useEffect(() => {
        if (!venueId) return;

        const setVenueName = async () => {
            const { venue } = await getVenue(parseInt(venueId));
            setPropertyName(venue.name);
        };

        setVenueName();
    }, [venueId]);

    return (
        <RowContainer>
            <LeftSection>
                <SectionHeader>
                    <Body1 fontWeight={600}>
                        {propertyName}
                    </Body1>
                </SectionHeader>
                <SectionBody>
                    <SecondaryContainer>
                        <ImageWithSkeleton src={TeamWorkImage} alt="No events" width="100" height="100" />

                        <Box style={{ flex: 1 }}>
                            <Body1 size="large" fontWeight="bold">Good morning {user.firstName},</Body1>
                            <Body2>
                                Welcome to your Bizly command center. This dashboard will give you visibility of every
                                item that needs your attention. Let's start by completing the tasks on the right to get
                                started. You can always invite your colleagues to help out.
                            </Body2>
                        </Box>
                    </SecondaryContainer>
                    <PrimaryContainer>
                        <Row itemSpacing="small">
                            <TipsAndUpdatesIcon />
                            <Heading2>Quick Tips</Heading2>
                        </Row>
                        <StyledUl>
                            <StyledLi>
                                Responding to RFPs within 24 hours increases your chances of winning an inquiry by 80%.
                            </StyledLi>
                            <StyledLi>
                                Venues with completed profiles receive 8x more inquiries than incomplete ones.
                            </StyledLi>
                            <StyledLi>Venues with high-quality photos get 5x more inquiries.</StyledLi>
                            <StyledLi>
                                Detailed descriptions that highlight unique features drive 40% more engagement from
                                planners.
                            </StyledLi>
                            <StyledLi>Venues with unique highlights attract 3x more inquiries.</StyledLi>
                        </StyledUl>
                    </PrimaryContainer>
                    <OutLineContainer>
                        <HelpOutlineIcon />
                        <Box style={{ flex: 1 }}>
                            <Body1 fontWeight={600}>Have questions or feedback?</Body1>
                            <Body2 fontWeight={500}>Contact us at venues@bizly.com. We’re here to help!</Body2>
                        </Box>
                    </OutLineContainer>
                </SectionBody>
            </LeftSection>
            <TaskDashboard />
        </RowContainer>
    );
}
