import { i18n } from 'translation';
import { formatCurrency } from 'utils';

export const guestRoomsSubTotal = (guestroom: Bizly.VenueDayGR) =>
    guestroom?.quantity ? guestroom.rate * guestroom.quantity : null;
export const eventSpacesSubTotal = (eventSpaces: Bizly.EventSpace[], useDdr?: boolean) =>
    eventSpaces?.length > 0
        ? eventSpaces?.reduce((agg, cur) => {
              return agg + (useDdr ? (cur.ratePerPerson ?? 0) * (cur.minGuests ?? 0) : cur.fbMinimum + cur.rate);
          }, 0)
        : null;

export const getTotalDisplay = (proposalColumns: Bizly.VenueProposal, isOnriva?: boolean) => {
    const useDdr = !!proposalColumns?.useDdr;
    const currencyCode = proposalColumns.currency?.code;
    const grSubtotalVal = proposalColumns.byDay?.reduce(
        (sum, day) => sum + (guestRoomsSubTotal(day.guestRooms[0]) ?? 0),
        0
    );
    const esSubtotalVal = proposalColumns?.byDay?.reduce(
        (sum, day) => sum + (eventSpacesSubTotal(day.eventSpaces, useDdr) ?? 0),
        0
    );

    const PLATFORM_FEE_RATE = 0.04;
    const subtotal = grSubtotalVal + esSubtotalVal;
    let platformFee = 0;
    let totalProposal = subtotal + platformFee;

    if (isOnriva) {
        platformFee = subtotal * PLATFORM_FEE_RATE;
        totalProposal += platformFee;
    }

    return {
        totalProposal: formatCurrency(totalProposal, currencyCode),
        platformFee: formatCurrency(platformFee, currencyCode),
        subtotal: formatCurrency(subtotal, currencyCode),
    };
};

export const getDayTotalDisplay = (proposalColumns: Bizly.VenueProposal, dayIndex: number) => {
    const useDdr = !!proposalColumns.useDdr;
    const currencyCode = proposalColumns.currency?.code;
    const grSubtotalVal = guestRoomsSubTotal(proposalColumns.byDay[dayIndex - 1].guestRooms[0]);
    const esSubtotalVal = eventSpacesSubTotal(proposalColumns.byDay[dayIndex - 1].eventSpaces, useDdr);
    const totalProposal = (grSubtotalVal ?? 0) + (esSubtotalVal ?? 0);

    return formatCurrency(totalProposal, currencyCode);
};

export const calculateBudgetStatus = (proposalColumns: Bizly.VenueProposal, user: Bizly.User, eventBudget?: string) => {
    const useDdr = !!proposalColumns.useDdr;
    const grSubtotalVal = proposalColumns?.byDay.reduce(
        (sum, day) => sum + (guestRoomsSubTotal(day.guestRooms[0]) ?? 0),
        0
    );
    const esSubtotalVal = proposalColumns?.byDay?.reduce(
        (sum, day) => sum + (eventSpacesSubTotal(day.eventSpaces, useDdr) ?? 0),
        0
    );

    const totalProposal = grSubtotalVal + esSubtotalVal;
    const totalConvertedProposal = totalProposal * Number(proposalColumns.forexRate);

    const isEventBudget =
        user.team?.authMeetingCreateFields?.includes('budget') || user?.team?.authMeetingCreateRedirect;
    const hasBudget = isEventBudget || Boolean(user?.team?.maximumBudget);
    const maxBudget = isEventBudget ? Number(eventBudget) : Number(user?.team?.maximumBudget);
    const budgetBufferPercentage = 1.1;
    const budgetWithBuffer = maxBudget ? maxBudget * budgetBufferPercentage : 0;

    const isAboveBudget = totalConvertedProposal > budgetWithBuffer;
    const isAboveMaximumBudgetDisplay = isAboveBudget
        ? i18n.venue.inquiry.aboveMaximumBudget
        : i18n.venue.inquiry.withinMaximumBudget;

    return { hasBudget, isAboveBudget, isAboveMaximumBudgetDisplay };
};
