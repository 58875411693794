import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Copy } from 'ui';
import { HEADER_HEIGHT } from 'utils/header';

const FOOTER_HEIGHT = 84;

const Container = styled(Box)`
    width: 38rem;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const DrawerHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1.25rem;
    box-sizing: border-box;
    height: ${HEADER_HEIGHT}px;

    transition: 0.3s all ease-in-out;

    ${({ theme: { getColor, EColors } }) =>
        `
        background: ${getColor(EColors.pureWhite)};
        border-bottom: 1px solid ${getColor(EColors.grey)};
    `}
`;

const Title = styled(Copy)`
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
`;

const Subtitle = styled(Copy)`
    font-size: 0.875rem;
`;

const DrawerBody = styled(Box)`
    padding: 1.25rem;
    box-sizing: border-box;
    flex: 1;
`;

const DrawerFooter = styled(Box)`
    height: ${FOOTER_HEIGHT}px;
    padding: 1.25rem;
    box-sizing: border-box;
    ${({ theme: { getColor, EColors } }) =>
        `
        background: ${getColor(EColors.pureWhite)};
        box-shadow: 0 1px 5px ${getColor(EColors.grey)};
    `}
`;

type Props = PropsWithChildren<{
    drawerOpen: boolean;
    onClose?: () => void;
    title?: string;
    subtitle?: string;
    footer?: React.ReactNode;
}>;

const RightDrawer = ({ drawerOpen, onClose, title, subtitle, children, footer }: Props) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
            <Container>
                <DrawerHeader>
                    <Stack gap={1}>
                        <Title>{title}</Title>
                        {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    </Stack>
                    <IconButton onClick={handleClose}>
                        <DoubleArrowIcon fontSize="medium" />
                    </IconButton>
                </DrawerHeader>
                <DrawerBody>{children}</DrawerBody>
                {footer && <DrawerFooter>{footer}</DrawerFooter>}
            </Container>
        </Drawer>
    );
};

export default RightDrawer;
