import { Box, styled } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BorderedContainer } from '../GridContainer';
import { Body2 } from '../Typography/Typography';

const Container = styled(BorderedContainer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    padding: theme.spacing(2.5),
    overflowY: 'auto',
}));

const StyledMenuItem = styled(Box)(({ theme: { getColor, EColors, shape }, selected }) => ({
    backgroundColor: selected ? getColor(EColors.bizlyOSPrimary, 0.2) : 'transparent',
    padding: '11.5px 8px',
    borderRadius: shape.borderRadius,
    cursor: 'pointer',
    '&:hover': !selected && {
        backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    },
}));

export const MAIN_PATH_WITH_PARAM = '/os/listings/:listingId';
const MAIN_PATH = '/os/listings';

export const GENERALINFO_PATH = '/general-info';
export const PHOTOS_PATH = '/photos';
export const CONTACT_INFO_PATH = '/contact-info';
export const EXPERIENCE_AMENITIES_PATH = '/experience-amenities';
export const HIGHLIGHTS_PATH = '/highlights';
export const GUEST_ROOMS_PATH = '/guest-rooms';
export const EVENT_SPACES_PATH = '/event-spaces';
export const OTHER_SPACES_PATH = '/other-spaces';
export const FAQS_PATH = '/faqs';
export const POLICIES_PATH = '/policies';

const MENU_ITEMS = [
    { title: 'General Info', path: GENERALINFO_PATH },
    { title: 'Photos', path: PHOTOS_PATH },
    { title: 'Contact Info', path: CONTACT_INFO_PATH },
    { title: 'Experience & Amenities', path: EXPERIENCE_AMENITIES_PATH },
    { title: 'Highlights', path: HIGHLIGHTS_PATH },
    { title: 'Guest Rooms', path: GUEST_ROOMS_PATH },
    { title: 'Event Spaces', path: EVENT_SPACES_PATH },
    { title: 'Other Spaces', path: OTHER_SPACES_PATH },
    { title: 'FAQs', path: FAQS_PATH },
    { title: 'Policies', path: POLICIES_PATH },
];

export default function ListingsSubNavigation() {
    const { listingId } = useParams() as { listingId: string };
    const location = useLocation();

    return (
        <Container>
            {MENU_ITEMS.map(menuItem => {
                const isActive = location.pathname.includes(menuItem.path);
                return (
                    <Link to={`${MAIN_PATH}/${listingId}${menuItem.path}`} key={menuItem.path}>
                        <StyledMenuItem selected={isActive}>
                            <Body2 fontWeight={500}>{menuItem.title}</Body2>
                        </StyledMenuItem>
                    </Link>
                );
            })}
        </Container>
    );
}
