import * as Sentry from '@sentry/react';
import { searchVenues } from 'api/venues';
import { sortAndStringify, toQueryParams } from 'components/VenueSearch/SearchUtils';
import { TFilterValue } from 'components/VenueSearch/VenueSearchFilters';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';

const PAGE_SIZE = 24;

export type TVenuesQuery = {
    filters: TFilterValue;
    eventId?: number;
    latLng?: Bizly.Location;
    page: number; // starts from 1
    pageSize?: number;
};

export type TVenuesQueryResponse = {
    venues: Bizly.Venue[];
};

export const useVenuesQuery = ({ filters, eventId, latLng, page, pageSize = PAGE_SIZE }: TVenuesQuery) => {
    const [totalPages, setTotalPages] = useState(0);
    const [preferredTotal, setPreferredTotal] = useState(0);
    const [nonPreferredTotal, setNonPreferredTotal] = useState(0);
    const [previousQuery, setPreviousQuery] = useState('');

    const pageLoadingRef = useRef(false);

    // Memoize queryKey to only change if filters or latLng change
    const queryKey = useMemo(() => sortAndStringify({ ...filters, ...latLng }), [filters, latLng]);
    const filtersQuery = toQueryParams({ ...filters, ...latLng });

    const getSearchMeta = useMemo(
        () =>
            debounce(async () => {
                if (previousQuery !== queryKey) {
                    pageLoadingRef.current = true;
                    // get meta data for preferred venues
                    const { meta: preferredMeta } = await searchVenues(
                        eventId,
                        { ...filtersQuery, preferredOnly: true },
                        {
                            page: 1,
                            perPage: 1,
                        }
                    );
                    setPreferredTotal(preferredMeta.total);
                    const { meta: nonPreferredMeta } = await searchVenues(eventId, filtersQuery, {
                        page: 1,
                        perPage: 1,
                    });
                    setNonPreferredTotal(nonPreferredMeta.total);
                    pageLoadingRef.current = false;
                    setPreviousQuery(queryKey);
                }
            }, 300),
        [eventId, queryKey, filtersQuery, previousQuery]
    );

    useEffect(() => {
        if (previousQuery === queryKey) {
            setTotalPages(Math.ceil((preferredTotal + nonPreferredTotal) / pageSize));
        }
    }, [previousQuery, queryKey, pageSize, preferredTotal, nonPreferredTotal]);

    useEffect(() => {
        getSearchMeta();
    }, [queryKey]);

    const { data, isLoading } = useQuery<TVenuesQueryResponse>({
        queryKey: ['venues', eventId, queryKey, page, pageSize],
        queryFn: async () => {
            const venues: Bizly.Venue[] = [];
            let startIndex = (page - 1) * pageSize;
            if (startIndex < preferredTotal) {
                const { venues: preferredVenues } = await searchVenues(
                    eventId,
                    { ...filtersQuery, preferredOnly: true },
                    {
                        page,
                        perPage: pageSize,
                    }
                );
                venues.push(...preferredVenues);
            }

            if (venues.length < pageSize) {
                // calculate optimized page size and number
                startIndex = Math.max(startIndex - preferredTotal, 0);
                const remainingCount = pageSize - venues.length;
                let newPageSize = remainingCount;
                let pageIndex = Math.floor(startIndex / newPageSize);
                let pageEnd = (pageIndex + 1) * newPageSize;
                while (pageEnd < startIndex + remainingCount) {
                    newPageSize++;
                    pageIndex = Math.floor(startIndex / newPageSize);
                    pageEnd = (pageIndex + 1) * newPageSize;
                }
                const { venues: nonPreferredVenues } = await searchVenues(eventId, filtersQuery, {
                    page: pageIndex + 1,
                    perPage: newPageSize,
                });
                venues.push(
                    ...nonPreferredVenues.slice(
                        startIndex - pageIndex * newPageSize,
                        startIndex - pageIndex * newPageSize + remainingCount
                    )
                );
            }

            return {
                venues,
            };
        },
        onError: (error: unknown) => {
            console.error('Error fetching venues:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useVenuesQuery',
                },
            });
        },
        enabled: queryKey === previousQuery,
    });

    return {
        total: preferredTotal + nonPreferredTotal,
        totalPages,
        data,
        isLoading,
        isLoadingPages: pageLoadingRef.current,
    };
};
