import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import RightDrawer from 'components/Drawer/RightDrawer';
import Form from 'components/Form';
import { Button } from 'components/Ui-V2/Button/Button';
import { EventType, STATUS_LABELS } from 'constants/event';
import { useTeamMembersQuery } from 'hooks/queries/useQueries';
import { Moment } from 'moment';
import { useUser } from 'providers/user';
import { useCallback, useMemo } from 'react';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';

const errors = {};

const statusOptions = Array.from(new Set(Object.values(STATUS_LABELS)));

const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
    {
        label: 'Next month',
        getValue: () => {
            const today = tzMoment();
            const end = today.clone().add(1, 'month');
            return [today, end];
        },
    },
    {
        label: 'Next 3 months',
        getValue: () => {
            const today = tzMoment();
            const end = today.clone().add(3, 'month');
            return [today, end];
        },
    },
    {
        label: 'Next 6 months',
        getValue: () => {
            const today = tzMoment();
            const end = today.clone().add(6, 'month');
            return [today, end];
        },
    },
    {
        label: 'Next 12 months',
        getValue: () => {
            const today = tzMoment();
            const end = today.clone().add(12, 'month');
            return [today, end];
        },
    },
];

export type TEventFilters = {
    types: EventType[];
    status: string[];
    startDate?: DateRange<Moment>;
    owners: BizlyAPI.TeamMember[];
    collaborators: Bizly.EventCollaborator[];
};

export type TEventFiltersForm = {
    types: EventType[];
    status: string[];
    startDate?: DateRange<Moment>;
    owners: number;
    collaborators: number;
};

type TEventFiltersProps = {
    collaborators: Bizly.EventCollaborator[];
    filters: TEventFilters;
    onFilterChange: (filterVal: TEventFilters) => void;
    open: boolean;
    onClose: () => void;
    ownerDisabled?: boolean;
};

export default function EventFilters({
    collaborators,
    filters,
    onFilterChange,
    open,
    onClose,
    ownerDisabled,
}: TEventFiltersProps) {
    const { user } = useUser();
    const { data } = useTeamMembersQuery(user.team?.id ?? '');

    const formValues = useMemo(() => {
        let owner = undefined;
        if (filters.owners && filters.owners.length > 0) {
            owner = filters.owners[0].user.id;
        }
        let collaborator = undefined;
        if (filters.collaborators && filters.collaborators.length > 0) {
            collaborator = filters.collaborators[0].userId;
        }
        return {
            ...filters,
            owners: owner,
            collaborators: collaborator,
        };
    }, [filters]);

    const formFields = useMemo(() => {
        const memberOptions = (data?.members ?? [])
            .filter(member => member.user.firstName || member.user.lastName)
            .map(member => ({
                id: member.user.id,
                name: [member.user.firstName ?? '', member.user.lastName ?? ''].join(' ').trim() || '',
            }));
        const collaboratorOptions = collaborators
            .filter(collaborator => collaborator.firstName || collaborator.lastName)
            .map(collaborator => ({
                id: collaborator.userId,
                name: [collaborator.firstName ?? '', collaborator.lastName ?? ''].join(' ').trim() || '',
            }));
        return {
            types: {
                prompt: i18n.homepage.eventFilters.type,
                type: 'multiselect',
                options: {
                    options: Object.values(EventType),
                },
                optional: true,
            },
            status: {
                prompt: i18n.homepage.eventFilters.status,
                type: 'multiselect',
                options: {
                    options: statusOptions,
                    perRow: 2,
                },
                optional: true,
            },
            startDate: {
                prompt: i18n.homepage.eventFilters.startDate,
                type: 'date_range',
                options: {
                    shortcutsItems,
                    startEndLabel: {
                        start: 'MM/DD/YYYY',
                        end: 'MM/DD/YYYY'
                    },
                },
                optional: true,
            },
            owners: {
                prompt: i18n.homepage.eventFilters.owner,
                type: 'autocomplete',
                options: {
                    options: memberOptions,
                    disabled: ownerDisabled,
                },
                optional: true,
            },
            collaborators: {
                prompt: i18n.homepage.eventFilters.collaborators,
                type: 'autocomplete',
                options: {
                    options: collaboratorOptions,
                },
                optional: true,
            },
            divider: {
                type: Divider,
                prompt: ' ',
                optional: true,
            },
        };
    }, [data, collaborators, ownerDisabled]);

    const formSchema = [
        {
            key: 'types',
            fields: ['types'],
            spacing: null,
        },
        {
            key: 'divider1',
            fields: ['divider'],
            spacing: 'smallish',
        },
        {
            key: 'status',
            fields: ['status'],
            spacing: null,
        },
        {
            key: 'divider2',
            fields: ['divider'],
            spacing: 'smallish',
        },
        {
            key: 'startDate',
            fields: ['startDate'],
            spacing: 'smallish',
        },
        {
            key: 'divider3',
            fields: ['divider'],
            spacing: 'smallish',
        },
        {
            key: 'owners',
            fields: ['owners'],
            spacing: 'smallish',
        },
        {
            key: 'divider3',
            fields: ['divider'],
            spacing: 'smallish',
        },
        {
            key: 'collaborators',
            fields: ['collaborators'],
        },
    ];

    const handleEventChange = ({ value }: { field: keyof typeof formFields; value: TEventFiltersForm }) => {
        // convert number value to team members array
        const owners: BizlyAPI.TeamMember[] = [];
        if (value.owners) {
            const owner = data?.members.find(m => m.user.id === value.owners);
            if (owner) {
                owners.push(owner);
            }
        }

        // convert number form value into collaborators array
        const collaboratorFilters: Bizly.EventCollaborator[] = [];
        if (value.collaborators) {
            const collaborator = collaborators.find(c => c.userId === value.collaborators);
            if (collaborator) {
                collaboratorFilters.push(collaborator);
            }
        }

        onFilterChange({
            ...value,
            owners,
            collaborators: collaboratorFilters,
        });
    };

    const onClear = useCallback(() => {
        onFilterChange({} as TEventFilters);
    }, [onFilterChange]);

    return (
        <RightDrawer drawerOpen={open} onClose={onClose} title={i18n.venue.filterBy}>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={onClear}>
                    {i18n.button.reset}
                </Button>
            </Box>
            <Form
                fields={formFields}
                schema={formSchema}
                value={formValues}
                errors={errors}
                onChange={handleEventChange}
            />
        </RightDrawer>
    );
}
