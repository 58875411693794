import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { AreaUnit, units } from 'stores/measurement-units';
import { i18n } from 'translation';
import { formatCurrency, formatDate, formatPercentage } from 'utils';
import { tzMoment } from 'utils/moment';
import { sqftToSqm } from 'utils/units';
import {
    DayHeader,
    StyledAccordionSummaryContainer,
    StyledGridCellText,
    StyledGridContent,
    StyledHeaderTableRowContent,
    StyledSectionHeader,
} from './CompareModalStyledComponents';
import RenderContentRow from './RenderContentRow';
import { eventSpacesSubTotal, getDayTotalDisplay } from './compareUtils';

type DayRowsProps = {
    proposalColumns: Bizly.VenueProposal[];
    day: Bizly.VenueProposal['byDay'][number];
    dayNumber: number;
    areaUnit: AreaUnit;
};

function DayRows(props: DayRowsProps): JSX.Element {
    const { proposalColumns, day, dayNumber, areaUnit } = props;
    const [open, setOpen] = useState(false);

    const getEventSpaceByDay = (proposalColumns: Bizly.VenueProposal, dayNumber: number, idx: number) => {
        return proposalColumns.byDay[dayNumber - 1].eventSpaces[idx];
    };

    const renderEventSpacesTotal = (proposal: Bizly.VenueProposal, day: Bizly.VenueProposal['byDay'][number]) => {
        const useDdr = !!proposal.useDdr;
        const currencyCode = proposal.currency?.code;
        const eventSpacesSubTotalVal = eventSpacesSubTotal(day.eventSpaces, useDdr);
        const eventSpacesSubTotalDisplay = formatCurrency(eventSpacesSubTotalVal, currencyCode);

        return <StyledGridCellText fontWeight={500}>{eventSpacesSubTotalDisplay}</StyledGridCellText>;
    };

    return (
        <Box>
            <StyledGridContent onClick={() => setOpen(!open)}>
                <Box>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </Box>
                <Box>
                    <DayHeader>
                        {i18n.common.dayIndex(dayNumber)} - {tzMoment(day.day).format('ddd, ll')}
                    </DayHeader>
                </Box>
                {proposalColumns.map((venue, key) => (
                    <Box key={key}>
                        <Typography fontSize={14} fontWeight={500}>
                            {getDayTotalDisplay(venue, dayNumber)}
                        </Typography>
                    </Box>
                ))}
            </StyledGridContent>

            {day.eventSpaces.length > 0 && (
                <StyledAccordionSummaryContainer open={open}>
                    {/* Meeting Space */}
                    {day.eventSpaces.map((_, idx) => {
                        const eventSpacesLength = day.eventSpaces.length;
                        const hasMoreThanOneEventSpace = eventSpacesLength > 1;

                        return (
                            day.eventSpaces?.length > 0 && (
                                <Fragment key={idx}>
                                    <StyledHeaderTableRowContent key={idx} open={open}>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <StyledSectionHeader>
                                                <Typography>
                                                    Meeting Space {hasMoreThanOneEventSpace ? `${idx + 1}` : ''}
                                                </Typography>
                                            </StyledSectionHeader>
                                        </Collapse>
                                    </StyledHeaderTableRowContent>

                                    {/* Attendees */}
                                    <RenderContentRow
                                        open={open}
                                        title="Attendees"
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>
                                                {proposal.byDay[dayNumber - 1].eventSpaces[idx]?.guests ??
                                                    i18n.proposalForm.eventSpaces.noSpaceAvailable}
                                            </Fragment>
                                        )}
                                    />

                                    {/* Time  */}
                                    <RenderContentRow
                                        open={open}
                                        title="Time"
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <StyledGridCellText>
                                                {formatDate(getEventSpaceByDay(proposal, dayNumber, idx)?.startsAt)} -{' '}
                                                {formatDate(getEventSpaceByDay(proposal, dayNumber, idx)?.endsAt)}
                                            </StyledGridCellText>
                                        )}
                                    />

                                    {/* Room Name */}
                                    <RenderContentRow
                                        open={open}
                                        title={i18n.venue.inquiry.roomName}
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>
                                                {getEventSpaceByDay(proposal, dayNumber, idx)?.venueSpace?.name}
                                            </Fragment>
                                        )}
                                    />

                                    {/* Room Size */}
                                    <RenderContentRow
                                        open={open}
                                        title={i18n.venue.inquiry.roomSizeWithUnit(areaUnit)}
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>
                                                {areaUnit === units.sqm
                                                    ? sqftToSqm(
                                                          getEventSpaceByDay(proposal, dayNumber, idx)?.venueSpace?.size
                                                      )
                                                    : getEventSpaceByDay(proposal, dayNumber, idx)?.venueSpace?.size ??
                                                      '-'}
                                            </Fragment>
                                        )}
                                    />

                                    {/* Max Capacity */}
                                    <RenderContentRow
                                        open={open}
                                        title={i18n.venue.inquiry.roomMaxCapacity}
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>
                                                {getEventSpaceByDay(proposal, dayNumber, idx)?.venueSpace
                                                    ?.maxCapacity ?? '-'}
                                            </Fragment>
                                        )}
                                    />

                                    {/* Room Rate */}
                                    <RenderContentRow
                                        open={open}
                                        title={i18n.venue.inquiry.roomRate}
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>
                                                {getEventSpaceByDay(proposal, dayNumber, idx)?.rate
                                                    ? formatCurrency(
                                                          getEventSpaceByDay(proposal, dayNumber, idx)?.rate,
                                                          proposal.currency?.code ?? '-'
                                                      )
                                                    : '-'}
                                            </Fragment>
                                        )}
                                    />

                                    {/* F&B Min */}
                                    <RenderContentRow
                                        open={open}
                                        title={i18n.venue.inquiry.fbMin}
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>
                                                {getEventSpaceByDay(proposal, dayNumber, idx)?.fbMinimum
                                                    ? formatCurrency(
                                                          getEventSpaceByDay(proposal, dayNumber, idx)?.fbMinimum,
                                                          proposal.currency?.code
                                                      )
                                                    : '-'}
                                            </Fragment>
                                        )}
                                    />

                                    {/* Total Exclusive of Taxes and Fees */}
                                    <RenderContentRow
                                        open={open}
                                        title="Total (Excl. Taxes & Fees)"
                                        isTitleBold
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => renderEventSpacesTotal(proposal, day)}
                                    />

                                    {/* Service Fee */}
                                    <RenderContentRow
                                        open={open}
                                        title="Service Fee"
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>{formatPercentage(proposal.serviceCharge)}</Fragment>
                                        )}
                                    />

                                    {/* Sales Tax */}
                                    <RenderContentRow
                                        open={open}
                                        title={i18n.venue.inquiry.salesTax}
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>{formatPercentage(proposal?.salesTax)}</Fragment>
                                        )}
                                    />

                                    {/* Gratuity */}
                                    <RenderContentRow
                                        open={open}
                                        title={i18n.venue.inquiry.gratuity}
                                        proposalColumns={proposalColumns}
                                        renderContent={proposal => (
                                            <Fragment>{formatPercentage(proposal?.gratuity)}</Fragment>
                                        )}
                                    />
                                </Fragment>
                            )
                        );
                    })}
                </StyledAccordionSummaryContainer>
            )}

            {day.guestRooms.length > 0 && (
                <StyledAccordionSummaryContainer open={open}>
                    {/* Guest Rooms */}
                    {day.guestRooms.map((_, idx) => {
                        const getGuestRoomByDay = (
                            proposalColumns: Bizly.VenueProposal,
                            dayNumber: number,
                            idx: number
                        ) => {
                            return proposalColumns.byDay[dayNumber - 1].guestRooms[idx];
                        };

                        return (
                            <>
                                <StyledHeaderTableRowContent key={idx} open={open}>
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <StyledSectionHeader>
                                            <Typography>Guest Rooms</Typography>
                                        </StyledSectionHeader>
                                    </Collapse>
                                </StyledHeaderTableRowContent>

                                {/* Guest rooms per proposal */}
                                <RenderContentRow
                                    open={open}
                                    title="Guest Rooms per Night"
                                    proposalColumns={proposalColumns}
                                    renderContent={proposal => (
                                        <Fragment>{getGuestRoomByDay(proposal, dayNumber, idx).quantity}</Fragment>
                                    )}
                                />

                                {/* Room Name */}
                                <RenderContentRow
                                    open={open}
                                    title={i18n.venue.inquiry.roomName}
                                    proposalColumns={proposalColumns}
                                    renderContent={proposal => (
                                        <Fragment>{getGuestRoomByDay(proposal, dayNumber, idx).name}</Fragment>
                                    )}
                                />

                                {/* Room Rate */}
                                <RenderContentRow
                                    open={open}
                                    title={i18n.venue.inquiry.roomRate}
                                    proposalColumns={proposalColumns}
                                    renderContent={proposal => (
                                        <Fragment>
                                            {formatCurrency(
                                                getGuestRoomByDay(proposal, dayNumber, idx).rate,
                                                proposal.currency?.code
                                            )}
                                        </Fragment>
                                    )}
                                />

                                {/* Total Exclusive of Taxes and Fees */}
                                <RenderContentRow
                                    open={open}
                                    title="Total (Excl. Taxes & Fees)"
                                    isTitleBold
                                    proposalColumns={proposalColumns}
                                    renderContent={proposal => (
                                        <Fragment>
                                            {formatCurrency(
                                                getGuestRoomByDay(proposal, dayNumber, idx).subTotal,
                                                proposal.currency?.code
                                            )}
                                        </Fragment>
                                    )}
                                />

                                {/* Service Charge */}
                                <RenderContentRow
                                    open={open}
                                    title={i18n.venue.inquiry.occupancyTax}
                                    proposalColumns={proposalColumns}
                                    renderContent={proposal => (
                                        <Fragment>{Number(proposal?.occupancyTax) + '%'}</Fragment>
                                    )}
                                />

                                {/* Resort Fee */}
                                <RenderContentRow
                                    open={open}
                                    title={i18n.venue.inquiry.resortFee}
                                    proposalColumns={proposalColumns}
                                    renderContent={proposal => (
                                        <Fragment>
                                            {' '}
                                            {formatCurrency(proposal?.resortFee, proposal.currency?.code)}
                                        </Fragment>
                                    )}
                                />
                            </>
                        );
                    })}
                </StyledAccordionSummaryContainer>
            )}
        </Box>
    );
}

export default DayRows;
