import { styled } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { EColors } from 'types/themenum';

interface CustomTypographyProps extends TypographyProps {
    color?: EColors;
    underline?: boolean;
    italic?: boolean;
    lineHeight?: string;
}

interface CustomTypographyBodyProps extends CustomTypographyProps {
    size?: 'large' | 'small';
}

const shouldForwardProp = (prop: PropertyKey) =>
    prop !== 'color' && prop !== 'underline' && prop !== 'italic' && prop !== 'lineHeight';

const shouldForwardBodyProp = (prop: PropertyKey) =>
    prop !== 'color' && prop !== 'underline' && prop !== 'italic' && prop !== 'size' && prop !== 'lineHeight';

const StyledDisplay = styled(Typography, {
    shouldForwardProp,
})(({ theme: { getColor }, color = 'pureBlack', underline = false, italic = false, lineHeight = '120%' }) => ({
    color: getColor(color),
    fontSize: '2.625rem',
    fontWeight: 'bold',
    lineHeight,
    fontStyle: italic ? 'italic' : 'none',
    textDecoration: underline ? 'underline' : 'none',
}));

const StyledHeading1 = styled(Typography, {
    shouldForwardProp,
})(({ theme: { getColor }, color = 'pureBlack', underline = false, italic = false, lineHeight = '120%' }) => ({
    color: getColor(color),
    fontSize: '1.875rem',
    fontWeight: 'bold',
    lineHeight,
    fontStyle: italic ? 'italic' : 'none',
    textDecoration: underline ? 'underline' : 'none',
}));

const StyledHeading2 = styled(Typography, {
    shouldForwardProp,
})(({ theme: { getColor }, color = 'pureBlack', underline = false, italic = false, lineHeight = '120%' }) => ({
    color: getColor(color),
    fontSize: '1.5rem',
    lineHeight,
    fontWeight: 'bold',
    fontStyle: italic ? 'italic' : 'none',
    textDecoration: underline ? 'underline' : 'none',
}));

const StyledHeading3 = styled(Typography, {
    shouldForwardProp,
})(({ theme: { getColor }, color = 'pureBlack', underline = false, italic = false, lineHeight = '120%' }) => ({
    color: getColor(color),
    fontSize: '1.5rem',
    lineHeight,
    fontWeight: 600,
    fontStyle: italic ? 'italic' : 'none',
    textDecoration: underline ? 'underline' : 'none',
}));

const StyledHeading4 = styled(Typography, {
    shouldForwardProp,
})(({ theme: { getColor }, color = 'pureBlack', underline = false, italic = false, lineHeight = '120%' }) => ({
    color: getColor(color),
    fontSize: '1.375rem',
    lineHeight,
    fontWeight: 600,
    fontStyle: italic ? 'italic' : 'none',
    textDecoration: underline ? 'underline' : 'none',
}));

const StyledHeading5 = styled(Typography, {
    shouldForwardProp,
})(({ theme: { getColor }, color = 'pureBlack', underline = false, italic = false, lineHeight = '120%' }) => ({
    color: getColor(color),
    fontSize: '1.25rem',
    lineHeight,
    fontWeight: 500,
    fontStyle: italic ? 'italic' : 'none',
    textDecoration: underline ? 'underline' : 'none',
}));

const StyledBody1 = styled(Typography, {
    shouldForwardProp: shouldForwardBodyProp,
})(({ theme: { getColor }, color = 'pureBlack', size = 'small', lineHeight = '150%' }) => ({
    color: getColor(color),
    fontSize: size === 'small' ? '1rem' : '1.125rem',
    lineHeight,
}));

const StyledBody2 = styled(Typography, {
    shouldForwardProp: shouldForwardBodyProp,
})(({ theme: { getColor }, color = 'pureBlack', size = 'small', lineHeight = '150%' }) => ({
    color: getColor(color),
    fontSize: size === 'small' ? '0.875rem' : '1rem',
    lineHeight,
}));

const StyledCaption = styled(Typography, {
    shouldForwardProp: shouldForwardBodyProp,
})(({ theme: { getColor }, color = 'pureBlack', size = 'small', lineHeight = '150%' }) => ({
    color: getColor(color),
    fontSize: size === 'small' ? '0.625rem' : '0.75rem',
    lineHeight,
}));

export const Display = (props: CustomTypographyProps) => {
    return <StyledDisplay {...props} />;
};

export const Heading1 = (props: CustomTypographyProps) => {
    return <StyledHeading1 {...props} />;
};

export const Heading2 = (props: CustomTypographyProps) => {
    return <StyledHeading2 {...props} />;
};

export const Heading3 = (props: CustomTypographyProps) => {
    return <StyledHeading3 {...props} />;
};

export const Heading4 = (props: CustomTypographyProps) => {
    return <StyledHeading4 {...props} />;
};

export const Heading5 = (props: CustomTypographyProps) => {
    return <StyledHeading5 {...props} />;
};

export const Body1 = (props: CustomTypographyBodyProps) => {
    return <StyledBody1 {...props} />;
};

export const Body2 = (props: CustomTypographyBodyProps) => {
    return <StyledBody2 {...props} />;
};

export const Caption = (props: CustomTypographyBodyProps) => {
    return <StyledCaption {...props} />;
};
