import { Box } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { EColors } from 'theme';
import { Body2, Caption } from '../Typography/Typography';
import Tiptap from './TipTap';

type TextAreaFieldProps = {
    label?: string;
    error: FieldError | undefined;
    onChange: (text: string) => void;
    onBlur: () => void;
    value: string | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
};

const RichTextEditorField = React.forwardRef<HTMLDivElement, TextAreaFieldProps>(
    ({ label, error, required = false, ...field }) => {
        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Body2 fontWeight={500}>{label}</Body2>
                        {required && (
                            <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                                *
                            </Body2>
                        )}
                    </Box>
                )}

                <Tiptap description={field.value || ''} onChange={field.onChange} />
                {!!error && (
                    <Caption size="large" color="red" fontWeight={500} marginBottom={1}>
                        {error.message}
                    </Caption>
                )}
            </Box>
        );
    }
);

export default RichTextEditorField;
