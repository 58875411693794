import { Box, Input, InputProps, styled } from '@mui/material';
import React, { HTMLInputTypeAttribute, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

const StyledInputField = styled(Input)<InputProps & { errror: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    flex: 1,
    boxSizing: 'border-box',
    height: '100%',
    fontSize: '0.875rem',
    border: 'none',
    borderRadius: '4px',
    padding: 0,
    color: getColor(EColors.pureBlack),
    '& .MuiInput-input': {
        padding: 0,
    },
    '& .MuiInput-input:focus': {
        outline: 'none',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
    },
    '& .MuiInput-input:focus::placeholder': {
        color: getColor(EColors.pureBlack),
        opacity: 0.5,
    },
    '& .MuiInput-input::placeholder': {
        color: error ? 'red' : getColor(EColors.pureBlack),
    },
}));

const InputFieldWrapper = styled(Box, {
    shouldForwardProp: prop => prop !== 'error',
})(({ theme, error }) => ({
    color: getColor(EColors.pureBlack),
    padding: '14.5px 10px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    boxSizing: 'border-box',
    width: '100%',
    height: '50px',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: error ? 'red' : getColor(EColors.bizlyOSBorder),
    '&:focus-within': {
        border: `1px solid ${getColor(EColors.bizlyOSPrimary, 0.6)}`,
    },
}));

const OptionButton = styled(Body2)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
    fontWeight: '500',
    borderLeft: '1px solid ' + getColor(EColors.bizlyOSBorder),
    paddingLeft: '10px',
    cursor: 'pointer',
}));

type ToggleOption = { value: number | string; label: string };

type InputFieldProps = {
    label: string;
    placeholder?: string;
    error: FieldError | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeToggle: (value: string) => void;
    onBlur?: () => void;
    value: string | number | undefined;
    toggleValue: string | number | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
    type?: HTMLInputTypeAttribute;
    toggleOptions: ToggleOption[];
};

const InputFieldWithToggle = React.forwardRef<HTMLDivElement, InputFieldProps>(
    (
        {
            label,
            error,
            type = 'text',
            placeholder,
            required = false,
            toggleValue,
            toggleOptions = [],
            onChangeToggle,
            ...field
        },
        ref
    ) => {
        const selectedToggle: ToggleOption | undefined = useMemo(() => {
            if (!toggleValue) return undefined;

            const toggleOption = toggleOptions.find(option => option.value === toggleValue);

            if (!toggleOption) return undefined;

            return toggleOption;
        }, [toggleValue, toggleOptions]);

        const onClickToggle = () => {
            const index = toggleOptions.findIndex(option => option.value === toggleValue);
            if (index === toggleOptions.length - 1) {
                onChangeToggle(toggleOptions[0].value as string);
            } else {
                onChangeToggle(toggleOptions[index + 1].value as string);
            }
        };

        return (
            <Box>
                <Box display="flex" gap={0.5} marginBottom={1}>
                    <Body2 fontWeight={500}>{label}</Body2>
                    {required && (
                        <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                            *
                        </Body2>
                    )}
                </Box>
                <InputFieldWrapper error={!!error}>
                    <StyledInputField
                        type={type}
                        placeholder={placeholder ? placeholder : label}
                        error={!!error}
                        {...field}
                        disableUnderline
                        ref={ref}
                    />
                    <OptionButton onClick={onClickToggle}>{selectedToggle?.label || ''}</OptionButton>
                </InputFieldWrapper>
                {!!error && (
                    <Caption size="large" color="red" fontWeight={500} marginBottom={1}>
                        {error.message}
                    </Caption>
                )}
            </Box>
        );
    }
);

export default InputFieldWithToggle;
