import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Alert, Box, CircularProgress, Snackbar, SnackbarCloseReason, styled } from '@mui/material';
import { uploadBizlyOSImages } from 'cloudinary';
import { useListingImagesBatchMutation } from 'hooks/queries/BizlyOS/useListingQuery';
import PhotosUploadIcon from 'images/bizlyOS/icons/photos-upload-icon.svg?react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';
import { EColors, getColor } from 'theme';
import { Button } from '../Button/Button';
import TitleledContainer from '../TitleledContainer';
import { Body2 } from '../Typography/Typography';
import ImageSortable, { ImageType } from './ImageSortable';
import ListingActions from './ListingActions';

const EmptyUploadContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(0.5),
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
    borderRadius: shape.borderRadius,
    cursor: 'pointer',
}));

const UploadButton = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    cursor: 'pointer',
}));

function InitialImageUpload({
    getRootProps,
    getInputProps,
    isDragActive,
}: {
    getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
    isDragActive: boolean;
}) {
    return (
        <EmptyUploadContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <>
                    <CloudUploadOutlinedIcon fontSize="large" sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                    <Body2>Drop the files here</Body2>
                </>
            ) : (
                <>
                    <PhotosUploadIcon />
                    <Body2>
                        <span
                            style={{
                                color: getColor(EColors.bizlyOSPrimary),
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                        >
                            Click to upload
                        </span>{' '}
                        or drag and drop
                    </Body2>
                    <Body2>SVG, PNG, JPG or GIF (max. 3MB)</Body2>
                </>
            )}
        </EmptyUploadContainer>
    );
}

const handelImageUpload = async (file: File, id: string, fileName: string) => {
    try {
        return await uploadBizlyOSImages(file, id, fileName);
    } catch (error) {
        return null;
    }
};

export default function ListingPhotos({ listing }: { listing: Bizly.Venue }) {
    const listingImagesBatchMutation = useListingImagesBatchMutation(listing.id.toString(), () =>
        setOpenSnackbar(true)
    );
    const [images, setImages] = useState<ImageType[]>([]);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const numberOfSelectedImages = useMemo(() => {
        let count = 0;
        images.forEach(image => image.isSelected && count++);
        return count;
    }, [images]);

    const onDataChanged = () => {
        if (!isChanged) setIsChanged(true);
    };

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        try {
            if (acceptedFiles.length === 0) return;

            setIsUploading(true);

            const newImages: ImageType[] = [];

            for (let index = 0; index < acceptedFiles.length; index++) {
                const file = acceptedFiles[index];
                const fileNameSplit = file.name.split('.');
                const fileName = fileNameSplit[0];
                const uploadResult = await handelImageUpload(file, listing.id.toString(), fileName);

                if (uploadResult) {
                    newImages.push({
                        id: `${Date.now()}-${index}`,
                        srcUrl: uploadResult.secure_url,
                        isSelected: false,
                    });
                }
            }

            setImages(prev => [...prev, ...newImages]);
            onDataChanged();
        } catch (error: any) {
            setOpenSnackbar(true);
            setErrorMessage(error.message);
        } finally {
            setIsUploading(false);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/webp': ['.webp'],
        },
        noClick: false,
        maxSize: 1024 * 1024 * 5, //5mb
    });

    const onSelectedPhotosCancel = () => {
        setImages(images => images.map(image => ({ ...image, isSelected: false })));
    };

    const onSelectedPhotosDelete = () => {
        setImages(images => images.filter(image => !image.isSelected));
    };

    const setInitialImages = useCallback(() => {
        if (listing.images && listing.images.length > 0) {
            const formattedCurrentImages = listing.images.map(image => ({
                id: image.id.toString(),
                srcUrl: image.srcUrl,
                isSelected: false,
            }));

            setImages(formattedCurrentImages);
        }
    }, [listing]);

    const onSave = () => {
        const formattedImages = images.map((image, index) => ({
            src_url: image.srcUrl,
            order: index + 1,
        }));
        listingImagesBatchMutation.mutate(formattedImages);
        setIsChanged(false);
    };

    const onCancel = () => {
        setInitialImages();
        setIsChanged(false);
    };

    useLayoutEffect(() => {
        setInitialImages();
    }, [setInitialImages]);

    return (
        <>
            <TitleledContainer
                title={numberOfSelectedImages > 0 ? numberOfSelectedImages + ' photos selected' : 'Photos'}
                fullBodyHeight
                rightElement={
                    numberOfSelectedImages > 0 ? (
                        <Box display="flex" gap={1.25}>
                            <Button variant="outline" sx={{ height: 40 }} onClick={onSelectedPhotosCancel}>
                                Cancel
                            </Button>
                            <Button sx={{ height: 40 }} onClick={onSelectedPhotosDelete} color="error">
                                Delete
                            </Button>
                        </Box>
                    ) : isUploading ? (
                        <UploadButton>
                            <CircularProgress size={14} sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                            <Body2 fontWeight="500" color={EColors.bizlyOSPrimary}>
                                Uploading...
                            </Body2>
                        </UploadButton>
                    ) : (
                        <UploadButton onClick={open}>
                            <AddPhotoAlternateOutlinedIcon
                                fontSize="small"
                                sx={{ color: getColor(EColors.bizlyOSPrimary) }}
                            />
                            <Body2 fontWeight="500" color={EColors.bizlyOSPrimary}>
                                Upload
                            </Body2>
                        </UploadButton>
                    )
                }
            >
                {images.length > 0 ? (
                    <ImageSortable images={images} setImages={setImages} onChanged={onDataChanged} />
                ) : (
                    <InitialImageUpload
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        isDragActive={isDragActive}
                    />
                )}
            </TitleledContainer>
            <ListingActions
                state="edit"
                onSave={onSave}
                onCancel={onCancel}
                isChanges={isChanged}
                isSaving={listingImagesBatchMutation.isLoading}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{
                    '&.MuiSnackbar-root': { top: '65px', right: '20px' },
                }}
            >
                <Alert
                    severity="success"
                    icon={false}
                    variant="filled"
                    sx={{
                        width: '100%',
                        backgroundColor: !errorMessage ? getColor(EColors.bizlyOSPrimary) : 'red',
                    }}
                    onClose={handleClose}
                >
                    {errorMessage ? `Failed: ${errorMessage}` : 'Successfully Saved'}
                </Alert>
            </Snackbar>
        </>
    );

    function handleClose(_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
        if (reason === 'clickaway') return;
        setErrorMessage('');
        setOpenSnackbar(false);
    }
}
