import { i18n } from 'translation';

export const fields = {
    expiryDate: {
        type: 'date_outlined',
        perRow: '3',
        options: {
            minDate: Date.now(),
            format: 'MMM d, yyyy',
            minDateMessage: i18n.proposalForm.proposalNotes.error.minDate,
        },
    },
    additionalDetails: {
        type: 'textarea',
        prompt: i18n.proposalForm.proposalNotes.additionalDetails,
        description: i18n.proposalForm.proposalNotes.additionalDetailsDescription,
        perRow: '2/3',
        options: {
            autoHeight: true,
        },
    },
    attachments: {
        type: 'upload_list',
        perRow: '3',
        options: {
            prompt: i18n.proposalForm.proposalNotes.addAttachment,
        },
    },
};

const expiryNested = (timeDisplay: string) => ({
    type: 'nested',
    prompt: i18n.proposalForm.proposalNotes.spaceHeldUntil,
    description: i18n.proposalForm.proposalNotes.spaceHeldUntilDescription,
    schema: [
        {
            fields: [
                'expiryDate',
                {
                    type: 'display',
                    value: timeDisplay,
                },
            ],
            spacing: false,
        },
    ],
});

export const schema = (timeDisplay: string) => [
    { key: 'expiryDate', fields: [expiryNested(timeDisplay)] },
    { key: 'additionalDetails', fields: ['additionalDetails'], spacing: 'default' },
    { key: 'attachments', fields: ['attachments'] },
];
