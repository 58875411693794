import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors } from 'theme';
import {
    DrawerActionButtons,
    DrawerBodyContent,
    DrawerBodyFooter,
    SpaceDrawerChildrenProps,
} from './AddEventSpaceDrawer';

export default function AddEventSpaceCatering({ stepNumber, totalSteps, isLast }: SpaceDrawerChildrenProps) {
    return (
        <>
            <DrawerBodyContent>AddEventSpaceCatering</DrawerBodyContent>
            <DrawerBodyFooter>
                <Body2 fontWeight="500" color={EColors.bizlyOSPrimary}>
                    Step {stepNumber}/{totalSteps}
                </Body2>
                <DrawerActionButtons
                    onCancel={() => {}}
                    onSubmit={() => {}}
                    isSubmitting={false}
                    isValid={true}
                    isLast={isLast}
                />
            </DrawerBodyFooter>
        </>
    );
}
