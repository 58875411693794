import * as Sentry from '@sentry/react';
import { getVenueToken, pollForVenueContent } from 'api';
import { VENUE_CONTENT, VENUE_TOKEN } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import { z } from 'zod';

const VenueTokenResponseSchema = z.object({
    token: z.string(),
    success: z.boolean(),
});

type VenueTokenResponse = z.infer<typeof VenueTokenResponseSchema>;

const getVenueTokenWithValidation = async (venueId: string | number, eventId?: string): Promise<VenueTokenResponse> => {
    try {
        const response = await getVenueToken(venueId, eventId);
        const parsedResponse = VenueTokenResponseSchema.safeParse(response);

        if (!parsedResponse.success) {
            console.error('Validation Errors:', parsedResponse.error.errors);
            throw new Error('Invalid response format');
        }

        return parsedResponse.data;
    } catch (error) {
        console.error('Error in getVenueTokenWithValidation:', error);
        throw error;
    }
};

export const useVenueTokenQuery = (venueId: string | number, eventId?: string) => {
    return useQuery<VenueTokenResponse, Error>(
        [VENUE_TOKEN, venueId, eventId],
        () => getVenueTokenWithValidation(venueId, eventId),
        {
            onError: (error: unknown) => {
                console.error('Error fetching venue token:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useVenueTokenQuery',
                        venueId: String(venueId),
                    },
                });
            },
            staleTime: 5 * 60 * 1000, // 5 minutes
            cacheTime: 10 * 60 * 1000, // 10 minutes
        }
    );
};

const ContentItemSchema = z.object({
    key: z.string().optional(),
    section: z.string().optional(),
    content: z.string().optional(),
});

const ContentSchema = z.object({
    insights: z.array(ContentItemSchema),
    keyDetails: z.array(ContentItemSchema),
});

const VenueContentResponseSchema = z.object({
    status: z.string(),
    success: z.boolean(),
    content: ContentSchema.optional().nullable(),
});

type VenueContentResponse = z.infer<typeof VenueContentResponseSchema>;

const pollVenueContentWithValidation = async (
    venueId: string | number,
    token: string
): Promise<VenueContentResponse> => {
    try {
        const response = await pollForVenueContent(venueId, token);
        const parsedResponse = VenueContentResponseSchema.safeParse(response);

        if (!parsedResponse.success) {
            console.error('Validation Errors:', parsedResponse.error.errors);
            throw new Error('Invalid response format');
        }

        return parsedResponse.data;
    } catch (error) {
        console.error('Error in pollVenueContentWithValidation:', error);
        throw error;
    }
};

export const useVenueContentQuery = (venueId: string | number, token: string | null) => {
    const venueContent = useQuery<VenueContentResponse, Error>(
        [VENUE_CONTENT, venueId, token],
        async () => {
            if (!token) throw new Error('Token is required');
            return await pollVenueContentWithValidation(venueId, token);
        },
        {
            enabled: !!token,
            refetchInterval: data => {
                // Stop polling if we have no data or if the request failed
                if (!data?.success) {
                    return false;
                }

                // Stop polling if we've received completed status
                if (data?.status === 'completed') {
                    return false;
                }

                // Continue polling every 2 seconds while in progress
                return 2000;
            },
            onError: (error: Error) => {
                console.error('Error polling venue content:', error);
                Sentry.captureException(error, {
                    tags: {
                        component: 'useVenueContentQuery',
                        venueId: String(venueId),
                        token,
                    },
                });
            },
            onSuccess: data => {
                if (data?.status === 'completed') {
                    return data;
                }
            },
            retry: 20,
            staleTime: 0,
            cacheTime: 5 * 60 * 1000, // 5 minutes
        }
    );

    return {
        pollingState: venueContent?.data?.status,
        insights: venueContent?.data?.content?.insights,
        keyDetails: venueContent?.data?.content?.keyDetails,
        ...venueContent,
    };
};
