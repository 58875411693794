import { Box, styled } from '@mui/material';
import emailAnimationData from 'animations/magic-email.json';
import { Body1, Body2, Heading2 } from 'components/BizlyOS/Typography/Typography';
import Lottie from 'lottie-react';

const Container = styled(Box)(({ theme: { getColor, EColors } }) => ({
    flex: 1,
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor: getColor(EColors.pureWhite),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const AnimationContainer = styled(Box)({
    width: '6.25rem',
    height: '6.25rem',
    marginBottom: '1rem',
});

const Info = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    fontWeight: 500,
    margin: spacing(2.5) + ' 0',

    '& span': { color: getColor(EColors.bizlyOSPrimary), textDecoration: 'underline', cursor: 'pointer' },
}));

export default function Successful() {
    return (
        <Container>
            <Box maxWidth="600px">
                <AnimationContainer>
                    <Lottie animationData={emailAnimationData} loop={false} />
                </AnimationContainer>
                <Heading2 marginTop={1.25} marginBottom={1.25}>
                    Check Your Inbox (and Maybe Your Spam!)
                </Heading2>
                <Body1 marginBottom={2.5}>
                    We've sent a magic link to your email to verify your account. If you don't see it, take a quick peek
                    in your spam folder – sometimes these things get lost!
                </Body1>
                <Body1 marginBottom={6.25}>
                    The link expires in 30 minutes and can only be used once, so don't wait too long to continue your
                    Bizly journey!
                </Body1>
                <Info>
                    Didn’t get the email? <span>Click here to resend</span>
                </Info>
            </Box>
        </Container>
    );
}
